<template>
  <div class="col mainContent">
    <div class="d-flex m-0 p-0 pt-0 pt-lg-4 row text-center text-md-start">
      <div class="p-1 pb-0">
        <figure>
          <blockquote class="blockquote">
            <p>Server Analyse</p>
          </blockquote>
          <figcaption class="blockquote-footer">
            Älteste Datenerfassung:<cite title="Source Title"> 01.10.2023</cite>
          </figcaption>
        </figure>
      </div>
      <div class="p-1 pt-2 pt-sm-4 pb-0 col-sm-12 col-md-7 text-center text-md-start">
        <div class="dropdown-center d-grid w-50 mx-md-0 mx-auto">
          <button
            class="rounded-1 btn-outline-info btn btn-sm dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ selectedServer }}
          </button>
          <ul class="dropdown-menu w-100 rounded-0 dropdown-menu-dark bg-secondary">
            <li v-for="server in availableServers" :key="server" @click="selectServer(server)">
              <a class="dropdown-item">{{ server }}</a>
              <!-- set active  -->
            </li>
          </ul>
        </div>
      </div>
      <div class="p-1 pb-0 pt-4 col-sm-12 col-md-5 d-flex justify-content-md-end justify-content-center">
        <div class="dropdown-center ms-3">
          <button
            class="rounded-1 btn-outline-secondary btn btn-sm dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ filter_map[filter] }}
          </button>
          <ul class="dropdown-menu rounded-1 dropdown-menu-dark bg-secondary">
            <li @click="getServerAnalytics({ filter: 'today' })">
              <a
                :class="{
                  'dropdown-item': true,
                  active: filter === 'today',
                  'bg-dark': filter === 'today',
                }"
                >Heute</a
              >
            </li>
            <li @click="getServerAnalytics({ filter: 'yesterday' })">
              <a
                :class="{
                  'dropdown-item': true,
                  active: filter === 'yesterday',
                  'bg-dark': filter === 'yesterday',
                }"
                >Gestern</a
              >
            </li>
            <li @click="getServerAnalytics({ filter: '3days' })">
              <a
                :class="{
                  'dropdown-item': true,
                  active: filter === '3days',
                  'bg-dark': filter === '3days',
                }"
                >Letzte 3 Tage</a
              >
            </li>
            <li @click="getServerAnalytics({ filter: '7days' })">
              <a
                :class="{
                  'dropdown-item': true,
                  active: filter === '7days',
                  'bg-dark': filter === '7days',
                }"
                >Letzte 7 Tage</a
              >
            </li>
            <li @click="getServerAnalytics({ filter: '14days' })">
              <a
                :class="{
                  'dropdown-item': true,
                  active: filter === '14days',
                  'bg-dark': filter === '14days',
                }"
                >Letzte 14 Tage</a
              >
            </li>
            <li @click="getServerAnalytics({ filter: '30days' })">
              <a
                :class="{
                  'dropdown-item': true,
                  active: filter === '30days',
                  'bg-dark': filter === '30days',
                }"
                >Letzte 30 Tage</a
              >
            </li>
          </ul>
        </div>
        <div class="d-flex d-none d-md-block ms-3" role="search">
          <input
            class="form-control form-control-sm w-100 rounded-1 border-1 border-secondary shadow-none text-light"
            type="search"
            placeholder="🔍︎ Streamer..."
            aria-label="Suche"
            v-model="searchInput"
            @keydown.enter="search()"
          />
        </div>
        <!--<div class="dropdown-center ms-3">
          <button
            class="rounded-1 btn-outline-secondary btn btn-sm dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Sortieren nach
          </button>
          <ul class="dropdown-menu rounded-1 dropdown-menu-dark bg-secondary">
            <li>
              <a class="dropdown-item active bg-dark">Streaming-Zeit</a>
            </li>
            <li><a class="dropdown-item">Zuschauer</a></li>
          </ul>
        </div>-->
      </div>
      <div class="p-1 pt-md-3 pt-5 pb-0 col-sm-12 col-md-7 text-center text-md-start">
        <span v-if="selectedServer != 'Server auswählen'" class="text-secondary fs-6 text-info"
          ><span class="text-light">{{ selectedServer }} - </span>{{ filter_map[filter] }}
          <br class="d-block d-md-none" />
          - <span class="text-info">{{ this.$store.getters.gtav_getServerAnalytics.streamer_amount }} Streamer</span>
        </span>
      </div>
      <div class="p-1 pb-0 pt-0 pt-lg-3 col-sm-12 col-md-5 d-flex justify-content-md-end justify-content-center">
        <div class="d-flex mt-3 mt-lg-0 d-block d-md-none" role="search">
          <input
            class="form-control me-2 rounded-1 border-1 border-secondary shadow-none text-light"
            type="search"
            placeholder="🔍︎ Streamer, Server..."
            aria-label="Suche"
            v-model="searchInput"
          />
          <button class="btn btn-outline-secondary rounded-1" @click="search()">Suche</button>
        </div>
      </div>
    </div>
    <div class="row p-1 pt-0 ms-4 ms-md-auto me-4 me-md-auto" v-if="this.$store.getters.gtav_getLoadingState.server_analytics == false">
      <div class="text-center" v-if="selectedServer == 'Server auswählen'">
        <h4 class="text-info fw-normal mt-5 pt-4 fs-5">👆 Bitte wähle ein Server aus!</h4>
      </div>
      <div
        class="text-center"
        v-if="this.$store.getters.gtav_getServerAnalytics.streamer_amount == 0 && selectedServer != 'Server auswählen'"
      >
        <h4 class="text-secondary fw-normal mt-5 pt-4 fs-5">
          ❌ Für diesen Server oder Zeitraum wurden keine Ergebnisse gefunden!
        </h4>
      </div>
      <div
        class="text-secondary text-center"
        v-if="
          this.searchInput != '' &&
          this.allShown == true &&
          this.$store.getters.gtav_getServerAnalytics.streamerStats.length == 0
        "
      >
        <h4 class="fw-normal mt-5 pt-4 fs-5">⚠️ Keine Streamer gefunden!</h4>
      </div>
      <div
        class="col-xxl-3 col-lg-4 col-md-6 col-sm-6 pt-4 position-relative"
        v-for="(stats, index) in this.$store.getters.gtav_getServerAnalytics.streamerStats"
        :key="stats.streamer"
      >
      <a :href="'https://twitch.tv/'+stats.streamer" target="_blank" class="text-light ">
        <div class="streamerCard">
          <div class="d-flex pt-2 pb-3 p-0">
            <div class="mx-auto p-1 m-0">
              <img class="img-fluid rounded-circle mt-1" :src="stats.streamer_image" />
            </div>
            <div class="col-9 p-0 m-0 ps-2">
              <p class="p-0 m-0 pt-2 fs-5">
                <span class="text-info">{{ stats.ranking }}. </span>{{ stats.streamer }}
              </p>
              <div class="progress w-75 mt-2">
                <div
                  class="progress-bar progress-bar-striped bg-success"
                  role="progressbar"
                  :style="{ width: `${getProgressBarWidth(index)}%` }"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p class="fs-6 text-info fw-normal pt-2 mb-0">🕒 {{ stats.hours }} Stunden {{ stats.minutes }} Minuten</p>
              <!-- <p class="text-secondary">⌀ 145 Zuschauer</p> -->
            </div>
          </div>
        </div>
      </a>
      </div>
      <div
        class="text-center"
        v-if="
          selectedServer !== 'Server auswählen' &&
          this.$store.getters.gtav_getServerAnalytics.streamer_amount > 16 &&
          allShown == false
        "
      >
        <button
          class=" mx-auto mb-5 mt-5 btn btn-outline-info w-100"
          @click="getServerAnalytics(null, true)"
        >
          Alle Anzeigen
        </button>
      </div>
    </div>
          <!-- Hallo Liebe Besucher, tut uns Leid dass sie Warten müssen, die Optimierung der ServerAnalytics Ansicht läuft bereits. -->
          <div v-else style="width: 100%; text-align: center;" class="pt-5">
      <div class="lds-facebook"><div></div><div></div><div></div></div>
      <br>
      <span>Statistik wird erstellt...</span>
    </div>
  </div>
</template>
<script>
import gtav from "@/services/gtav";

export default {
  name: "ServerAnalyse",
  data() {
    return {
      availableServers: [],
      searchInput: "",
      wasSearched: false,
      filter: "today",
      allShown: false,
      filter_map: {
        today: "Heute",
        yesterday: "Gestern",
        "3days": "Letzte 3 Tage",
        "7days": "Letzte 7 Tage",
        "30days": "Letzte 30 Tage",
        "14days": "Letzte 14 Tage",
      },
      selectedServer: "Server auswählen",
    };
  },
  methods: {
    getProgressBarWidth(rank) {
      // Calculate the progress bar width based on rank or any other criteria.
      // You can adjust this logic as needed.
      const maxRank = this.$store.getters.gtav_getServerAnalytics.streamerStats.length;
      // Adjust the width based on the rank. For example, distribute evenly.
      return ((maxRank - rank) / maxRank) * 100;
    },
    async search() {
      //this.wasSearched = true;
      this.$router.push({ query: { query: this.searchInput } });
      if (this.searchInput == "") {
        gtav.getServerAnalytics(this.selectedServer, this.filter, false, this.searchInput);
        this.allShown = false;
      } else {
        gtav.getServerAnalytics(this.selectedServer, this.filter, true, this.searchInput);
        this.allShown = true;
      }

      //console.log(result);
    },
    selectServer(server) {
      this.selectedServer = server;
      this.allShown = false;
      //console.log(this.selectedServer);
      this.$router.push({
        query: {
          filter: this.filter,
          server: this.selectedServer,
          query: this.searchInput,
        },
      });
      gtav.getServerAnalytics(this.selectedServer, this.filter /*,searchQuery*/);
    },
    getServerAnalytics(data, all) {
      if (all == true) {
        this.allShown = true;
      } else {
        this.allShown = false;
      }

      if (data?.filter) {
        this.filter = data.filter;
      }
      if (!all) all = false;
      this.$router.push({
        query: {
          filter: this.filter,
          server: this.selectedServer,
          query: this.searchInput,
        },
      });
      gtav.getServerAnalytics(this.selectedServer, this.filter, all /*,searchQuery*/);
    },
  },
  async mounted() {
    let res = await gtav.getServerList(true);
    if (res.status == "success") {
      this.availableServers = res.message.list
        .filter((s) => s.serverName !== "Andere Server")
        .map((s) => s.serverName)
        .sort();

      this.availableServers.push("Andere Server");
    }
    if (this.$route.query.server) {
      //console.log(this.$route.query.server);
      this.selectedServer = this.$route.query.server;
    }
    if (this.$route.query.filter) {
      this.filter = this.$route.query.filter;
    }
  
    if (this.selectedServer && this.selectedServer !== "Server auswählen") {
      gtav.getServerAnalytics(this.selectedServer, this.filter /*,searchQuery*/);
    }
  },
};
</script>

<style scoped>
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #27d9ad;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
</style>