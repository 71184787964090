<template>
  <div class="col-2 pt-4 pb-4 sidebar overflow-auto sticky-top2 d-none d-lg-block">
    <div class="pointer" @click="this.$router.push({ name: 'home' })">
      <div class="d-flex sidebarServerName mt-2">
        <div class="flex-grow-1">Alle Streamer</div>
        <div class="text-secondary sidebarViewerCount">
          <span class="reddot pb-0 mt-2">🔴</span>
          <span class="ms-2" id="amountViewersX_Alle">{{
            formatNumber(this.$store.getters.gtav_getServerList["allStreamers"]["totalviewer_count"])
          }}</span>
        </div>
      </div>
      <div class="text-secondary">
        <span id="amountStreamersX_Alle">{{
          formatNumber(this.$store.getters.gtav_getServerList["allStreamers"]["liveCount"])
        }}</span>
        Streamer
      </div>
    </div>
    <hr class="w-25 text-light divider" />

    <div
      v-for="server in this.$store.getters.gtav_getServerList['list']"
      :key="server.serverName"
      class="pointer"
      @click="openServer(server.serverName)"
    >
      <hr class="w-25 text-light divider pb-1 mb-0" v-if="server.serverName == 'Andere Server'" />
      <div class="d-flex sidebarServerName mt-2">
        <div class="flex-grow-1">{{ server.serverName }}</div>
        <div class="text-secondary sidebarViewerCount">
          <span class="reddot pb-0 mt-2">🔴</span>
          <span class="ms-2" :id="'amountViewers_' + server.serverName">
            {{ formatNumber(server.totalviewer_count) }}
          </span>
        </div>
      </div>
      <div class="text-secondary">
        <span :id="'amountStreamers_' + server.serverName">{{ formatNumber(server.liveCount) }}</span> Streamer
      </div>
    </div>
    <hr class="divider w-25 text-secondary" />
    <!-- div class="col-xxl-7 m-0 p-0 pt-2">
            <router-link to="servercorp"
              ><img
                class="img-fluid rounded-0 mx-auto m-0 p-0"
                src="https://i.postimg.cc/VNJc4ZGs/Narco-City-Roleplay-2.png"
              />
            </router-link> -->
    <router-link to="impressum" class="d-inline-flex text-secondary mt-0">Impressum</router-link>
    <router-link to="datenschutz" class="ps-3 d-inline-flex text-secondary mb-4">Datenschutz</router-link>
  </div>

  <div class="d-lg-none p-3 pt-4 pb-0 pb-4">
    <div
      class="offcanvas offcanvas-start sidebarOffCanvasBG text-light"
      data-bs-scroll="true"
      tabindex="-1"
      id="offcanvasWithBothOptions"
      aria-labelledby="offcanvasWithBothOptionsLabel"
    >
      <div class="offcanvas-header">
        <span class="offcanvas-title" id="offcanvasWithBothOptionsLabel">
          <a class="navbar-brand" href="index.html">
            <img
              class="m-0 p-0 mainLogo"
              alt="Roleplay Streams Deutschland - rpstreams.de"
              src="https://i.postimg.cc/tT6XtJ8n/Logo2-Zeichenfl-che-1.png"
            />
          </a>
        </span>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <div class="pointer" @click="this.$router.push({ name: 'home' })">
          <div class="d-flex sidebarServerName mt-2">
            <div class="flex-grow-1" data-bs-dismiss="offcanvas">Alle Streamer</div>
            <div class="text-secondary sidebarViewerCount">
              <span class="reddot pb-0 mt-2">🔴&nbsp;</span>
              {{ formatNumber(this.$store.getters.gtav_getServerList["allStreamers"]["totalviewer_count"]) }}
            </div>
          </div>
          <div class="text-secondary" data-bs-dismiss="offcanvas">
            {{ formatNumber(this.$store.getters.gtav_getServerList["allStreamers"]["liveCount"]) }}
            Streamer
          </div>
        </div>
        <hr class="w-25 text-light divider pb-0" />
        <div
          v-for="server in this.$store.getters.gtav_getServerList['list']"
          :key="server.serverName"
          class="pointer"
          @click="openServer(server.serverName)"
        >
          <hr class="w-25 text-light divider pb-1 mb-0" v-if="server.serverName == 'Andere Server'" />
          <div class="d-flex sidebarServerName mt-2">
            <div class="flex-grow-1" data-bs-dismiss="offcanvas">
              {{ server.serverName }}
            </div>
            <div class="text-secondary sidebarViewerCount">
              <span class="reddot pb-0 mt-2">🔴&nbsp;</span>
              <span :id="'amountViewers_Andere Server'" class="ms-2">{{ formatNumber(server.totalviewer_count) }}</span>
            </div>
          </div>
          <div class="text-secondary" :id="'amountCounters_Andere Server'" data-bs-dismiss="offcanvas">
            {{ formatNumber(server.liveCount) }} Streamer
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
import gtav from "@/services/gtav";
export default {
  name: "SideBar",
  methods: {
    openServer(servername) {
      this.$router.push({ name: "server", params: { server: servername } });
    },
    formatNumber(number) {
      return gtav.formatNumber(number);
    },
  },
};
</script>
