<template>
  <nav class="navbar navbar-expand-lg navbar-dark sticky-top" aria-label="Offcanvas navbar large">
    <div class="container-fluid p-2">
      <a class="navbar-brand" href="/">
        <img class="m-0 p-md-2 mainLogo" alt="Roleplay Streams Deutschland - rpstreams.de" src="../assets/Logo.png" />
      </a>
      <div class="d-flex">
        <span
          class="p-2 ps-3 pe-3 align-self-center me-3 d-lg-none flex-row-reverse btn btn-outline-light rounded-0 shadow-none"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasWithBothOptions"
          aria-controls="offcanvasWithBothOptions"
          >Alle Server</span
        >
        <button
          class="navbar-toggler rounded-0 border-1 border-light shadow-none"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar2"
          aria-controls="offcanvasNavbar2"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div
        class="offcanvas offCanvasMenu offcanvas-start"
        tabindex="-1"
        id="offcanvasNavbar2"
        aria-labelledby="offcanvasNavbar2Label"
      >
        <div class="offcanvas-header">
          <span class="offcanvas-title" id="offcanvasNavbar2Label">
            <a class="navbar-brand" href="/">
              <img
                class="m-0 p-1 mainLogo"
                alt="Roleplay Streams Deutschland - rpstreams.de"
                src="../assets/Logo.png"
              />
            </a>
          </span>
          <button
            type="button"
            class="btn-close btn-close-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div class="d-flex pb-3 mt-lg-0 d-block d-lg-none" role="search">
            <input
              class="form-control ms-0 me-2 rounded-1 border-1 border-secondary text-light shadow-none"
              type="search"
              placeholder="🔍︎ Streamer, Server..."
              id="searchInput"
              @keydown.enter="search()"
              v-model="searchInput"
            />

            <button
              class="btn btn-outline-secondary text-secondary rounded-1"
              data-bs-dismiss="offcanvas"
              @click="search()"
            >
              Suche
            </button>
          </div>

          <ul class="navbar-nav justify-content-start flex-grow-1 pe-3">
            <li class="nav-item">
              <a
                :class="{ 'nav-link': true, active: isLinkActive('home') }"
                aria-current="page"
                @click="this.$router.push({ name: 'home' })"
                data-bs-dismiss="offcanvas"
              >
                Home
              </a>
            </li>
            <!-- <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Spiel-Kategorie
              </a>
              <ul
                class="dropdown-menu dropdown-menu-dark bg-secondary rounded-0"
              >
                <li>
                  <a
                    :class="{
                      'dropdown-item': true,
                      'active bg-dark': isLinkActive('gtav'),
                    }"
                    data-bs-dismiss="offcanvas"
                    @click="this.$router.push({ name: 'gtav' })"
                    >GTA V</a
                  >
                </li>
                <li>
                  <a
                    :class="{
                      'dropdown-item': true,
                      'active bg-dark': isLinkActive('rdr2'),
                    }"
                    data-bs-dismiss="offcanvas"
                    @click="this.$router.push({ name: 'rdr2' })"
                    >RDR II</a
                  >
                </li>
              </ul>
            </li>-->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                :class="{
                  'text-info': isLinkActive(['topserver', 'serveranalyse', 'spielerstats']),
                }"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Statistiken
              </a>
              <ul class="dropdown-menu dropdown-menu-dark bg-secondary rounded-0">
                <li>
                  <a
                    :class="{
                      'dropdown-item': true,
                      'active bg-dark': isLinkActive('topserver'),
                    }"
                    data-bs-dismiss="offcanvas"
                    @click="this.$router.push({ name: 'topserver' }) & clickDropdown($event)"
                    >Top Server</a
                  >
                </li>
                <li>
                  <a
                    :class="{
                      'dropdown-item': true,
                      'active bg-dark': isLinkActive('serveranalyse'),
                    }"
                    data-bs-dismiss="offcanvas"
                    @click="this.$router.push({ name: 'serveranalyse' }) & clickDropdown($event)"
                    >Server Analyse</a
                  >
                </li>
                <!---<li>
                  <a
                    :class="{
                      'dropdown-item': true,
                      'active bg-dark': isLinkActive('spielerstats'),
                    }"
                    @click="this.$router.push({ name: 'spielerstats' }) & clickDropdown($event)"
                    data-bs-dismiss="offcanvas"
                    href="spielerstats.html"
                    >Spieler Stats</a
                  >
                </li>-->
              </ul>
            </li>
            <li class="nav-item">
              <a
                :class="{ 'nav-link': true, active: isLinkActive('clips') }"
                data-bs-dismiss="offcanvas"
                @click="this.$router.push({ name: 'clips' })"
                >Top Clips</a
              >
            </li>
            <li class="nav-item">
              <a
                :class="{ 'nav-link': true, active: isLinkActive('faq') }"
                data-bs-dismiss="offcanvas"
                @click="this.$router.push({ name: 'faq' })"
                >Wissenwertes</a
              >
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                :class="{
                  'text-info': isLinkActive(['streamercorp', 'servercorp']),
                }"
                href="#"
                id="jetztMitmachen"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Jetzt Mitmachen!
              </a>
              <ul class="dropdown-menu dropdown-menu-dark bg-secondary rounded-0" aria-labelledby="jetztMitmachen">
                <li>
                  <a
                    :class="{
                      'dropdown-item': true,
                      'active bg-dark': isLinkActive('streamercorp'),
                    }"
                    data-bs-dismiss="offcanvas"
                    @click="this.$router.push({ name: 'streamercorp' }) & clickDropdown($event)"
                    >Streamer</a
                  >
                </li>
                <li>
                  <a
                    :class="{
                      'dropdown-item': true,
                      'active bg-dark': isLinkActive('servercorp'),
                    }"
                    data-bs-dismiss="offcanvas"
                    @click="this.$router.push({ name: 'servercorp' }) & clickDropdown($event)"
                    >Serverbesitzer</a
                  >
                </li>
              </ul>
            </li>

            <hr class="divider w-25 text-secondary d-block d-lg-none" />
            <li class="nav-item d-block d-lg-none">
              <a
                :class="{ 'nav-link': true, active: isLinkActive('impressum') }"
                data-bs-dismiss="offcanvas"
                @click="this.$router.push({ name: 'impressum' })"
                >Impressum</a
              >
            </li>
            <li class="nav-item d-block d-lg-none">
              <a
                :class="{
                  'nav-link': true,
                  active: isLinkActive('datenschutz'),
                }"
                data-bs-dismiss="offcanvas"
                @click="this.$router.push({ name: 'datenschutz' })"
                >Datenschutz</a
              >
            </li>
          </ul>
          <div class="d-flex pt-1 w-25 d-none d-lg-block pe-lg-1 pe-xl-3">
            <input
              class="form-control me-2 rounded-1 border-1 border-secondary text-light shadow-none"
              placeholder="🔍︎ Streamer, Server..."
              aria-label="Suche"
              v-model="searchInput"
              @keydown.enter="search()"
            />
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import gtav from "@/services/gtav/";
export default {
  name: "NavBar",
  data() {
    return {
      searchInput: "",
    };
  },
  methods: {
    async search() {
      this.$router.push({ name: "search", query: { query: this.searchInput } });
      let result = await gtav.searchAll(this.searchInput);
      console.log(result);
    },
    clickDropdown(event) {
      const item = event.target.parentElement.parentElement;
      console.log(item);
      //const dropdownToggle = this.$el.querySelector(".dropdown-toggle");
      if (item) {
        item.click();
      }
    },
    isLinkActive(routeName) {
      if (Array.isArray(routeName)) {
        return routeName.includes(this.$route.name);
      }
      return this.$route.name === routeName;
    },
  },
};
</script>
