<template>
  <div class="col mainContent">
    <div class="row pb-0 pb-xl-1 p-1 pt-0 pt-lg-4">
      <div
        v-for="(stream, index) in this.$store.getters.gtav_getRandomStreams"
        :key="stream.title"
        :class="index !== '1' ? 'col d-none d-xl-block position-relative' : 'col position-relative'"
      >
        <div id="twitch-embed" v-if="index == '1'" class="border-bottom border-2 border-info h-100"></div>
        <section v-else @click="handleClick(stream)" class="pointer">
          <img
            src="https://i.ibb.co/2sQkFXd/placeholder.jpg"
            alt=""
            class="placeholder-glow img-fluid w-100 border-0"
            v-if="!this.loadedUrls.includes(stream.thumbnail_url)"
          />
          <img
            class="img-fluid w-100 border-bottom border-2 border-info"
            :src="stream.thumbnail_url"
            :alt="stream.user_name + ' - ' + stream.title"
            :title="stream.user_name + ' - ' + stream.title"
            @load="this.loadedUrls.push(stream.thumbnail_url)"
          />
          <span
            class="z-3 position-absolute top-0 start-0 translate-middle badge mt-4 ms-5 p-1 rounded-0 bg-danger font-monospace fs-6"
            >LIVE</span
          >
          <div
            class="ps-3 randomStreamerOverlay d-flex position-absolute top-100 start-0 border-end border-2 border-info"
          >
            <div class="p-2">
              <img
                :src="stream.profile_image_url"
                :alt="stream.user_name"
                :title="stream.user_name"
                class="card-img-top rounded-circle"
              />
            </div>
            <div class="p-2 align-self-center">
              <h2 class="pt-2 fs-6">{{ stream.user_name }}</h2>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div
      @click="this.$router.push({ name: 'streamercorp' })"
      id="slide-in"
      class="pointer d-none d-xxl-block p-3 infoDiv position-absolute top-0 end-0 bg-info text-dark rounded-start-3 shadow d-flex justify-content-center align-items-center"
    >
      <span class="p-2 fw-bold">⭐ Kostenloser Stream Push!</span>
    </div>

    <div class="d-block d-xxl-none p-0 pe-1 d-flex justify-content-end align-items-center">
      <div class="pointer bg-dark p-2 pb-0 rounded-bottom-4" @click="this.$router.push({ name: 'streamercorp' })">
        <span class="fw-bold sponsoredStreamTitle">⭐ Kostenloser Stream Push!</span>
      </div>
    </div>
    <div class="row p-2 p-xxl-3">
      <div class="col-sm-6">
        <div class="row p-2">
          <div class="col-xxl-5 p-0 m-0 pe-3 pt-2 col d-none d-xxl-block">
            <p class="text-info m-0 p-0">Red River Roleplay</p>
            <h4 class="text-light">RDR II Roleplay</h4>
            <p class="text-secondary m-0 p-0">Einzigartige Skripts, serios RP und mehr. Jetzt Whitelisten!</p>
            <div class="btn-group btn-group-sm pt-3" role="group" aria-label="Small button group">
              <a href="https://discord.gg/redriver" target="_blank"
                ><button class="rounded-1 btn btn-outline-light">Discord beitreten</button></a
              >
            </div>
          </div>
          <div class="col-xxl-7 m-0 p-0 pt-2">
            <a href="https://discord.gg/redriver" target="_blank"
              ><img
                class="img-fluid rounded-0 mx-auto m-0 p-0"
                src="../assets/Red-River-Roleplay.png"
            /></a>
          </div>
        </div>
         </div>
       <div class="col-sm-6">
            <div class="row p-2">
              <div class="col-xxl-7 m-0 p-0 pt-2">
                <a href="https://www.schmuckmitgravur.de" target="_blank"
                  ><img
                    class="img-fluid rounded-0 mx-auto m-0 p-0"
                    src="https://i.postimg.cc/0jybdg86/Schmuck-mit-Gravur-Ring-mit-Gravur-Kette-mit-Gravur-Anh-nger-mit-Gravur-Verlobungsring-Silberri.jpg"
                  />
              </a>
              </div>
              <div class="col-xxl-5 p-0 m-0 ps-0 ps-xxl-3 pt-2 col d-none d-xxl-block">
                <p class="text-info m-0 p-0">schmuckmitgravur.de</p>
                <h4 class="text-light">Dein Schmuck mit Gravur</h4>
                <p class="text-secondary m-0 p-0">Ketten, Ringe, Armbänder und mehr für faire Preise!</p>
                <div class="btn-group btn-group-sm pt-3" role="group" aria-label="Small button group">
                    <a href="https://www.schmuckmitgravur.de" target="_blank">
                    <button class="rounded-1 btn btn-outline-light">Shop besuchen</button></a>
                  
                </div></div>
        </div>
      </div>
    </div>
    <div class="row p-2 pt-2">
      <!-- 
         data-bs-toggle="modal"
        data-bs-target="#liveStream"
       -->
      <div
        class="p-2 pt-4 pt-lg-3 streamerCard d-flex align-items-stretch"
        v-for="stream in this.$store.getters.gtav_getStreams.streams"
        :key="stream.title"
        @click="handleClick(stream)"
      >
        <div class="mb-0 pb-0 position-relative">
          <img
            src="https://i.ibb.co/2sQkFXd/placeholder.jpg"
            alt=""
            class="placeholder-glow card-img-top rounded-0"
            v-if="!this.loadedUrls.includes(stream.thumbnail_url)"
          />
          <img
            :src="stream.thumbnail_url"
            :title="stream.title"
            :alt="stream.title"
            :style="this.loadedUrls.includes(stream.thumbnail_url) ? '' : 'display: none;'"
            @load="this.loadedUrls.push(stream.thumbnail_url)"
            class="card-img-top rounded-0"
          />
          <span
            class="z-3 position-absolute top-0 start-0 translate-middle badge badgePosition p-1 rounded-0 bg-danger font-monospace fs-6"
            >LIVE</span
          >
          <div class="streamerViewer d-flex flex-column">
            <span class="streamerViewerCount">
              <img class="viewerIcon" src="https://i.ibb.co/zrtCSkT/group.png" />
              {{ formatNumber(stream.viewer_count) }}
            </span>
          </div>
          <div class="d-flex pt-2 p-0">
            <div class="mx-auto p-1 m-0">
              <img :title="stream.user_name" class="img-fluid rounded-circle mt-1" :src="stream.profile_image_url" />
            </div>
            <div class="col-10 p-0 m-0 ps-2">
              <p class="p-0 m-0 fw-bold" :title="stream.title">
                {{ formatTitle(stream.title) }}
              </p>
              <h6 class="fs-6 text-secondary fw-normal" :title="stream.user_name">
                {{ stream.user_name.length > 20 ? stream.user_name.substring(0, 20) + "..." : stream.user_name }}
              </h6>
              <p class="cardTags" :title="stream.tags">
                {{ stream.tags.length > 25 ? stream.tags.substring(0, 25) + "..." : stream.tags }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 
  <div
    class="modal bd-example-modal-lg fade p-0 m-0"
    id="liveStream"
    tabindex="-1"
    aria-labelledby="liveStreamLabel"
    aria-hidden="true"
    data-bs-backdrop="false"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered" style="max-width: 100%">
      <div class="modal-content border border-info bg-dark">
        <div class="modal-header mt-1 pt-1 pointer border-0 d-flex justify-content-between">
          <div>
            <h1 class="modal-title fs-6 pt-1 pt-sm-0" id="liveStreamLabel">{{ selectedStreamer }}</h1>
          </div>
          <div class="form-check form-switch d-flex align-items-center text-secondary mt-0 pt-0">
            <input
              class="form-check-input shadow-none pointer"
              type="checkbox"
              role="switch"
              v-model="chatVisible"
              id="flexSwitchCheckChecked"
            /><span class="pt-1">&nbsp;Twitch-Chat</span>
          </div>
          <div class="closeButton pt-3 pt-sm-0">
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>
        <div class="modal-body pt-0">
          <iframe
            :src="`https://player.twitch.tv/?channel=${selectedStreamer}&parent=localhost&muted=false`"
            height="520px"
            width="100%"
            allowfullscreen
          >
          </iframe>
        </div>
      </div>
    </div>
  </div> -->
</template>
<style>
#slide-in {
  right: -100% !important;
  top: 0 !important;
  transition: right 2s !important;
  z-index: 100;
}
#slide-in:hover {
  color: white !important;
}
#slide-in.activate {
  right: 0 !important; /* Slide the div in from the right when it has the "active" class */
}
</style>
<script>
import gtav from "@/services/gtav";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      page: 1,
      loadedUrls: [],
      selectedStreamer: "",
      selectedStreamerTest: [],
      chatVisible: false,
      modalCounter: 0,
      draggedStream: false,
      resizedStream: false,
      offsetX: null,
      offsetY: null,
      modalWidth: null,
      modalHeight: null,
      offsetX_resize: null,
      offsetY_resize: null,
    };
  },
  async mounted() {
    try {
      await gtav.getStreams(1);
      await gtav.getRandomStreams();
      document.querySelector("#slide-in").classList.add("activate");
      setInterval(this.refreshImages, 300000)
      //console.log(this.$store.getters.gtav_getRandomStreams[1].user_name);
      // eslint-disable-next-line
      new Twitch.Embed("twitch-embed", {
        width: "100%",
        height: "100%",
        channel: this.$store.getters.gtav_getRandomStreams[1].user_name,
        layout: "video",
        theme: "dark",
        autoplay: true,
        allowfullscreen: true,
        // Only needed if this page is going to be embedded on other websites
        parent: ["localhost", "rpstreams.com", "rpstreams.de", "test.rpstreams.de"],
      });
      console.log(document);

      window.addEventListener("scroll", this.checkLazyLoad);
    } catch (error) {
      console.log(error);
    }
  },
  unmounted() {
    window.removeEventListener("scroll", this.checkLazyLoad);
    console.log("unmounted");
  },
  methods: {
    refreshImages() {
  // Get all image elements on the page
  const images = document.querySelectorAll('img');

  images.forEach(image => {
    // Append a timestamp to the image src to force reload
    const src = image.src;
    const newSrc = src.split('?')[0] + '?t=' + new Date().getTime();
    image.src = newSrc;
  });
},
    handleClick(stream) {
      /*
       new Twitch.Embed("twitch-embed", {
        width: "100%",
        height: "100%",
        channel: stream.user_name,
        layout: this.chatVisible ? "video-with-chat" : "video",
        theme: "dark",
        autoplay: true,
        allowfullscreen: true,
        // Only needed if this page is going to be embedded on other websites
        parent: ["localhost", "rpstreams.com", "rpstreams.de", "test.rpstreams.de"],
      });
      */
      //console.log(stream);
      this.selectedStreamer = stream.user_name;
      let modals = this.$store.getters.gtav_getStreamerModals;
      if (!modals.includes(stream)) {
        modals.push(stream);
        this.$store.dispatch("gtavSetStreamerModals", modals);
        setTimeout(() => {
          let modal = document.getElementById(`joeTemplate_${this.selectedStreamer}`);
          modal.style.zIndex = this.findHighestZIndex() + 1;
        }, 100);
      }
    },
    findHighestZIndex() {
      const elements = document.querySelectorAll(".custom-modal");
      let highestZIndex = 9999;

      elements.forEach((element) => {
        const zIndex = getComputedStyle(element).zIndex;
        const zIndexValue = parseInt(zIndex, 10);

        if (!isNaN(zIndexValue) && zIndexValue > highestZIndex) {
          highestZIndex = zIndexValue;
        }
      });

      return highestZIndex;
    },
    replaceThumbnailWithActualImage(imageElement, thumbnailUrl) {
      var img = new Image();
      img.onload = function () {
        imageElement.src = thumbnailUrl;
      };
      img.src = thumbnailUrl;
    },
    checkLazyLoad() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
        if (this.page >= this.$store.getters.gtav_getStreams.totalPages) {
          return;
        }
        this.lazyLoadMore();
      }
    },
    lazyLoadMore() {
      this.page = this.page + 1;
      gtav.getStreams(this.page, true);
    },
    formatNumber(number) {
      return gtav.formatNumber(number);
    },
    formatTitle(title) {
      if (title.length > 20) {
        title = title.substring(0, 20) + "...";
      }
      var words = title.split(" ");
      for (var j = 0; j < words.length; j++) {
        if (words[j].length > 10 && words[j] === words[j].toUpperCase()) {
          words[j] = words[j].toLowerCase();
        }
      }

      title = words.join(" ");

      return title;
    },
  },
};
</script>
