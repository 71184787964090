<template>
  <div class="col mainContent">
    <div class="d-flex m-0 p-0 pt-0 pt-lg-4 row">
      <div class="p-1 pb-0 col-sm-12 col-md-6 text-center text-md-start">
        <figure>
          <blockquote class="blockquote">
            <p>Top Server</p>
          </blockquote>
          <figcaption class="blockquote-footer text-secondary">
            Alle Angaben werden auf den Durchschnitt pro Stunde gerechnet!
          </figcaption>
        </figure>
      </div>
      <div class="p-2 pb-0 col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-center align-items-center">
        <div class="form-check form-switch">
          <label class="form-check-label text-secondary pointer" for="flexSwitchCheckChecked"
            >Primetime von 18:00 - 02:00 Uhr</label
          >
          <input
            class="form-check-input pointer shadow-none"
            type="checkbox"
            role="switch"
            v-model="isChecked"
            @change="getTopServers()"
            id="flexSwitchCheckChecked"
          />
        </div>
      </div>
      <div class="p-1 pt-0 pt-sm-4 pb-0 col-sm-12 col-md-6 text-center text-md-start">
        <span class="text-secondary fs-6 text-info"
          ><br class="d-block d-md-none" /><span class="text-light"
            >{{ filter_map[filter] }} in 🇩🇪 - {{ isChecked ? "zur Primetime" : "in 24 Stunden" }}</span
          ><br />
          ⌀
          {{ formatNumber(this.$store.getters.gtav_getTopServers.avgStreamersTotal) }}
          Streamer - ⌀
          {{ formatNumber(this.$store.getters.gtav_getTopServers.avgViewersTotal) }}
          Zuschauer</span
        ><br class="d-block d-md-none" /><br class="d-block d-md-none" />
      </div>
      <div
        class="p-2 pb-0 pb-md-2 col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-center align-items-end"
      >
        <div class="dropdown-center">
          <button
            class="rounded-1 btn-outline-secondary btn btn-sm dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ filter_map[filter] }}
          </button>
          <ul class="dropdown-menu rounded-1 dropdown-menu-dark bg-secondary">
            <li @click="getTopServers({ filter: 'today' })">
              <a
                :class="{
                  'dropdown-item': true,
                  active: filter === 'today',
                  'bg-dark': filter === 'today',
                }"
                >Heute</a
              >
            </li>
            <li @click="getTopServers({ filter: 'yesterday' })">
              <a
                :class="{
                  'dropdown-item': true,
                  active: filter === 'yesterday',
                  'bg-dark': filter === 'yesterday',
                }"
                >Gestern</a
              >
            </li>
            <li @click="getTopServers({ filter: '3days' })">
              <a
                :class="{
                  'dropdown-item': true,
                  active: filter === '3days',
                  'bg-dark': filter === '3days',
                }"
                >Letzte 3 Tage</a
              >
            </li>
            <li @click="getTopServers({ filter: '7days' })">
              <a
                :class="{
                  'dropdown-item': true,
                  active: filter === '7days',
                  'bg-dark': filter === '7days',
                }"
                >Letzte 7 Tage</a
              >
            </li>
            <li @click="getTopServers({ filter: '14days' })">
              <a
                :class="{
                  'dropdown-item': true,
                  active: filter === '14days',
                  'bg-dark': filter === '14days',
                }"
                >Letzte 14 Tage</a
              >
            </li>
            <li @click="getTopServers({ filter: '30days' })">
              <a
                :class="{
                  'dropdown-item': true,
                  active: filter === '30days',
                  'bg-dark': filter === '30days',
                }"
                >Letzte 30 Tage</a
              >
            </li>
            <li @click="getTopServers({ filter: '90days' })">
              <a
                :class="{
                  'dropdown-item': true,
                  active: filter === '90days',
                  'bg-dark': filter === '90days',
                }"
                >Letzte 90 Tage</a
              >
            </li>
          </ul>
        </div>
        <div class="dropdown-center ms-3">
          <button
            class="rounded-1 btn-outline-secondary btn btn-sm dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Sortiert nach {{ sort_map[sort] }}
          </button>
          <ul class="dropdown-menu rounded-1 dropdown-menu-dark bg-secondary">
            <li @click="getTopServers({ sort: 'viewers' })">
              <a
                :class="{
                  'dropdown-item': true,
                  active: sort === 'viewers',
                  'bg-dark': sort === 'viewers',
                }"
                >Zuschauer</a
              >
            </li>
            <li @click="getTopServers({ sort: 'streamers' })">
              <a
                :class="{
                  'dropdown-item': true,
                  active: sort === 'streamers',
                  'bg-dark': sort === 'streamers',
                }"
                >Streamer</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row p-1 pt-0" v-if="this.$store.getters.gtav_getLoadingState.topservers == false">
      <div
        class="col-xxl-3 topServerCard col-lg-4 col-md-6 col-sm-6 pt-4 position-relative red"
        v-for="(server, index) in this.$store.getters.gtav_getTopServers.serverData"
        :key="server.name"
      >
        <img
          class="img-fluid topServerImg w-100 opacity-25 border-bottom border-2 border-info"
          :src="getBackgroundUrl(server.name)"
          :alt="server.name"
        />

        <div
          class="d-flex serverStatsOverlay position-absolute top-50 start-50 translate-middle border-end border-start border-1 border-info"
        >
          <div class="p-2 align-self-center">
            <h2 class="pt-2 fs-5">
              <span class="text-info fs-6">{{ parseInt(index) + 1 }}. </span>{{ server.name }}
            </h2>
          </div>
        </div>
        <div>
          <div class="d-flex p-0 pb-0 pt-2">
            <div class="flex-fill">
              <p>
                <span class="text-info">⌀ {{ formatNumber(server.avg_streamers) }}</span>
                Streamer
              </p>
            </div>
            <div class="flex-fill text-end">
              <p>
                <span class="text-info">⌀ {{ formatNumber(server.avg_viewers) }}</span>
                Zuschauer
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
          <!-- Hallo Liebe Besucher, tut uns Leid dass sie Warten müssen, die Optimierung der TopServer Ansicht läuft bereits. -->
    <div v-else style="width: 100%; text-align: center;" class="pt-5">
      <div class="lds-facebook"><div></div><div></div><div></div></div>
      <br>
      <span>Statistik wird erstellt...</span>
    </div>
  </div>
</template>
<style scoped>
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #27d9ad;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
</style>
<script>

import gtav from "@/services/gtav/";

const serverBackgrounds = {
  "4Blocks": "https://4kwallpapers.com/images/walls/thumbs_uwide/13058.jpg",
  "9Pixel": "https://4kwallpapers.com/images/walls/thumbs_uwide/13089.jpg",
  "Astro Life": "https://4kwallpapers.com/images/walls/thumbs_uwide/13096.jpg",
  "Berlin City": "https://4kwallpapers.com/images/walls/thumbs_uwide/13112.jpeg",
  Biergarten: "https://4kwallpapers.com/images/walls/thumbs_uwide/13078.jpeg",
  Corleone: "https://4kwallpapers.com/images/walls/thumbs_uwide/13088.jpg",
  "Die Insel": "https://4kwallpapers.com/images/walls/thumbs_uwide/13070.jpg",
  Fastlife: "https://4kwallpapers.com/images/walls/thumbs_uwide/13105.jpg",
  "Final City": "https://4kwallpapers.com/images/walls/thumbs_uwide/13062.jpg",
  "Final City III": "https://4kwallpapers.com/images/walls/thumbs_uwide/13062.jpg",
  "Gold City": "https://4kwallpapers.com/images/walls/thumbs_uwide/13111.jpg",
  GVMP: "https://images.hdqwalls.com/download/gta-5-zr380-yx-2560x1080.jpg",
  "Grand RP": "https://4kwallpapers.com/images/walls/thumbs_uwide/13107.jpg",
  "Home State": "https://4kwallpapers.com/images/walls/thumbs_uwide/13108.jpeg",
  "Hope Life": "https://4kwallpapers.com/images/walls/thumbs_uwide/13106.jpg",
  "Hope V": "https://4kwallpapers.com/images/walls/thumbs_uwide/12991.jpg",
  Immortal: "https://4kwallpapers.com/images/walls/thumbs_uwide/13018.jpeg",
  "Iron V": "https://4kwallpapers.com/images/walls/thumbs_uwide/12959.jpg",
  Klaerwerk: "https://4kwallpapers.com/images/walls/thumbs_uwide/13038.jpg",
  "Lucky V": "https://4kwallpapers.com/images/walls/thumbs_uwide/12994.jpeg",
  "Majestic RP": "https://4kwallpapers.com/images/walls/thumbs_uwide/12957.jpg",
  "Mexico City": "https://4kwallpapers.com/images/walls/thumbs_uwide/12997.jpg",
  "Modern V": "https://4kwallpapers.com/images/walls/thumbs_uwide/12976.jpg",
  "Mount Doom": "https://4kwallpapers.com/images/walls/thumbs_uwide/12979.jpg",
  "Narco City": "https://4kwallpapers.com/images/walls/thumbs_uwide/12977.jpg",
  "Pruda V": "https://4kwallpapers.com/images/walls/thumbs_uwide/12785.jpg",
  "Sektor RP": "https://4kwallpapers.com/images/walls/thumbs_uwide/12831.jpg",
  Sibaui: "https://4kwallpapers.com/images/walls/thumbs_uwide/12793.jpg",
  "State V": "https://4kwallpapers.com/images/walls/thumbs_uwide/12774.jpg",
  "Story Time": "https://4kwallpapers.com/images/walls/thumbs_uwide/12798.jpg",
  "Supremo City": "https://4kwallpapers.com/images/walls/thumbs_uwide/12792.jpg",
  "Unity Life": "https://4kwallpapers.com/images/walls/thumbs_uwide/12839.jpg",
  "New Life": "https://4kwallpapers.com/images/walls/thumbs_uwide/12796.jpg",
};

export default {
  name: "TopServer",
  data() {
    return {
      isChecked: true,
      sort: "viewers",
      filter: "today",
      filter_map: {
        today: "Heute",
        yesterday: "Gestern",
        "3days": "Letzte 3 Tage",
        "7days": "Letzte 7 Tage",
        "30days": "Letzte 30 Tage",
        "14days": "Letzte 14 Tage",
        "90days": "Letzte 90 Tage",
      },
      sort_map: {
        streamers: "Streamer",
        viewers: "Zuschauer",
      },
      // Serverinformationen aus dem serverBackgrounds-Objekt lesen
      thumbnails: Object.keys(serverBackgrounds).map((name) => ({
        name,
        url: serverBackgrounds[name],
      })),
    };
  },
  async mounted() {
    if (this.$route.query.sort) {
      this.sort = this.$route.query.sort;
    }
    if (this.$route.query.filter) {
      this.filter = this.$route.query.filter;
    }
    gtav.getTopServers(this.sort, this.filter, this.isChecked);
  },
  methods: {
    formatNumber(number) {
      return gtav.formatNumber(number);
    },
    getTopServers(data) {
      if (data?.filter) {
        this.filter = data.filter;
      }
      if (data?.sort) {
        this.sort = data.sort;
      }
      this.$router.push({
        query: {
          sort: this.sort,
          filter: this.filter,
          primetime: this.isChecked,
        },
      });
      gtav.getTopServers(this.sort, this.filter, this.isChecked);
    },
    getBackgroundUrl(serverName) {
      return serverBackgrounds[serverName] || "";
    },
  },
};
</script>
