<template>
  <NavBar></NavBar>
  <div class="container-fluid">
    <div class="row">
      <SideBar></SideBar>
      <div
        class="custom-modal border border-info bg-dark p-3 rounded-2"
        style="border: inset 2px #27d9ad !important"
        :id="'joeTemplate_' + streamer.user_name"
        v-for="streamer in this.$store.getters.gtav_getStreamerModals"
        :key="streamer.user_name"
        @mousedown="handleMouseDown($event, streamer.user_name)"
        @mouseup="handleMouseUp($event, streamer.user_name)"
      >
        <div class="custom-modal-header bg-dark mt-0 pt-0 border-0 d-flex justify-content-between">
          <h1 class="modal-title fs-6 pt-1 pt-sm-0" id="modalTitle">{{ streamer.user_name }}</h1>
          <div class="form-check form-switch d-flex align-items-center text-secondary mt-0 pt-0">
            <input
              class="form-check-input shadow-none pointer"
              type="checkbox"
              role="switch"
              @change="handleTwitchChat($event, streamer.user_name)"
              id="flexSwitchCheckChecked"
            /><span class="pt-1">&nbsp;Twitch-Chat</span>
          </div>
          <span class="modal-close pt-3 pt-sm-0 lh-1" id="modalClose" @click="closeModal(streamer.user_name)"
            >&times;</span
          >
        </div>
        <div class="custom-modal-body h-100" id="twitch-embed">
          <iframe
            :src="`https://player.twitch.tv/?channel=${streamer.user_name}&parent=rpstreams.de&muted=false&&layout=video-and-chat`"
            height="90%"
            width="100%"
            style="position: relative; z-index: -1"
            allowfullscreen
            v-if="streamer.twitchChat == undefined || streamer.twitchChat == false"
            :id="'iframe_' + streamer.user_name"
          >
          </iframe>
          <div class="twitchEmbedClass" :id="'twitchEmbed_' + streamer.user_name" v-else></div>
          <!-- <div
        class="resizable-handle"
        @mousedown="handleMouseDown_resize($event, streamer.user_name)"
        @mouseup="handleMouseUp($event, streamer.user_name)"
      ></div> -->
          <p class="ps-2 text-secondary d-none d-md-block" style="font-size: 0.7em">
            Für eine optimale Ansicht empfehlen wir den Browser auf Vollbild zu stellen.
          </p>
        </div>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import SideBar from "./components/SideBar.vue";
import gtav from "@/services/gtav";
export default {
  components: { NavBar, SideBar },
  async mounted() {
    await gtav.getServerList();
    setInterval(async () => {
      await gtav.getServerList();
      let all = this.$store.getters.gtav_getServerList.allStreamers;
      //console.log(all);
      this.counter(`amountStreamersX_Alle`, parseInt(all.liveCount_old), parseInt(all.liveCount), 2000);
      this.counter(`amountViewersX_Alle`, parseInt(all.totalviewer_count_old), parseInt(all.totalviewer_count), 2000);

      this.$store.getters.gtav_getServerList.list?.forEach((s) => {
        if (s.cssLive == true) {
          this.counter(`amountStreamers_${s.serverName}`, parseInt(s.cssLiveOld), s.liveCount, 2000);
        }
        if (s.cssViewer == true) {
          this.counter(`amountViewers_${s.serverName}`, parseInt(s.cssViewerOld), s.totalviewer_count, 2000);
        }
      });
    }, 15000);
    document.addEventListener("mouseup", (e) => {
      this.handleMouseUp(e, null);
    });
    document.addEventListener("mousemove", (e) => {
      if (this.draggedStream) {
        let modal = document.getElementById(`joeTemplate_${this.draggedStream}`);
        const x = e.clientX - this.offsetX;
        const y = e.clientY - this.offsetY;
        // Get the dimensions of the modal
        const modalWidth = modal.offsetWidth;
        const modalHeight = modal.offsetHeight;

        // Get the dimensions of the window
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        // Calculate the maximum allowed positions
        const maxX = windowWidth - modalWidth - 8;
        const maxY = windowHeight - modalHeight;

        // Limit the x and y positions
        const constrainedX = Math.max(0, Math.min(x, maxX));
        const constrainedY = Math.max(0, Math.min(y, maxY));

        modal.style.left = constrainedX + "px";
        modal.style.top = constrainedY + "px";
      }
    });
  },
  methods: {
    handleTwitchChat(e, streamer) {
      let modals = this.$store.getters.gtav_getStreamerModals;
      modals.map((s) => {
        if (s.user_name == streamer) {
          console.log(e, s);
          if (s.twitchChat == undefined) {
            s.twitchChat = true;
            setTimeout(() => {
              // eslint-disable-next-line
              new Twitch.Embed("twitchEmbed_" + streamer, {
                width: "100%",
                height: "90%",
                allowfullscreen: true,
                channel: streamer,
                theme: "dark",
                layout: "video-with-chat",
                parent: ["localhost", "rpstreams.com", "rpstreams.de"],
              });
            }, 100);

            //e.target.parentElement.parentElement.parentElement.querySelectorAll("iframe")[0].remove();
          } else {
            s.twitchChat = !s.twitchChat;
            if (s.twitchChat == true) {
              setTimeout(() => {
                // eslint-disable-next-line
                new Twitch.Embed("twitchEmbed_" + streamer, {
                  width: "100%",
                  height: "90%",

                  allowfullscreen: true,
                  channel: streamer,
                  theme: "dark",
                  layout: "video-with-chat",
                  parent: ["localhost", "rpstreams.com", "rpstreams.de"],
                });
              }, 100);
            }
          }
        }
      });
      this.$store.dispatch("gtavSetStreamerModals", modals);
    },
    closeModal(streamer) {
      let modals = this.$store.getters.gtav_getStreamerModals;
      modals = modals.filter((s) => s.user_name !== streamer);
      this.$store.dispatch("gtavSetStreamerModals", modals);
    },

    isInResizeArea(e, modalRect) {
      // Define the width and height of the resize area
      const resizeAreaWidth = 20; // You can adjust this value
      const resizeAreaHeight = 20; // You can adjust this value

      // Calculate the coordinates of the bottom right corner
      const bottomRightX = modalRect.left + modalRect.width;
      const bottomRightY = modalRect.top + modalRect.height;

      // Check if the mouse click is within the resize area
      return (
        e.clientX >= bottomRightX - resizeAreaWidth &&
        e.clientX <= bottomRightX &&
        e.clientY >= bottomRightY - resizeAreaHeight &&
        e.clientY <= bottomRightY
      );
    },
    handleMouseDown(e, s) {
      let modal = document.getElementById(`joeTemplate_${s}`);
      console.log(this.isInResizeArea(e, modal.getBoundingClientRect()));

      if (this.isInResizeArea(e, modal.getBoundingClientRect())) return;
      this.draggedStream = s;
      //let zIndex = this.findHighestZIndex() + 1;
      let zIndex = this.findHighestZIndex() + 1;
      modal.style.zIndex = zIndex;

      let iframe = document.getElementById(`iframe_${this.draggedStream}`);
      if (iframe) {
        iframe.style.zIndex = -1;
      }
      let twitchEmbed = document.getElementById(`twitchEmbed_${this.draggedStream}`);
      if (twitchEmbed) {
        twitchEmbed.querySelector("iframe").style.position = "relative";
        twitchEmbed.querySelector("iframe").style.zIndex = -1;
      }
      //console.log("[move]", e, s, modal);
      this.offsetX = e.clientX - modal.getBoundingClientRect().left;
      this.offsetY = e.clientY - modal.getBoundingClientRect().top;
    },
    handleMouseUp(/*e, s*/) {
      let modal = document.getElementById(`joeTemplate_${this.draggedStream}`);
      if (modal) {
        //:id="'iframe_' + streamer.user_name"
        let iframe = document.getElementById(`iframe_${this.draggedStream}`);
        if (iframe) {
          iframe.style.zIndex = 1;
        }
        let twitchEmbed = document.getElementById(`twitchEmbed_${this.draggedStream}`);
        if (twitchEmbed) {
          twitchEmbed.querySelector("iframe").style.zIndex = 1;
        }
      }
      this.draggedStream = false;
      this.resizedStream = false;
      //console.log("[mouseUp]", e, s);
    },
    findHighestZIndex() {
      const elements = document.querySelectorAll(".custom-modal");
      let highestZIndex = 9999;

      elements.forEach((element) => {
        const zIndex = getComputedStyle(element).zIndex;
        const zIndexValue = parseInt(zIndex, 10);

        if (!isNaN(zIndexValue) && zIndexValue > highestZIndex) {
          highestZIndex = zIndexValue;
        }
      });

      return highestZIndex;
    },
    counter(id, start, end, duration) {
      if (start == end) return;
      let obj = document.getElementById(id),
        current = start,
        range = end - start,
        increment = end > start ? 1 : -1,
        step = Math.abs(Math.floor(duration / range)),
        timer = setInterval(() => {
          current += increment;
          obj.textContent = gtav.formatNumber(current);
          if (current == end) {
            clearInterval(timer);
            obj.textContent = gtav.formatNumber(obj.textContent);
          }
        }, step);
    },
  },
};
</script>
<style scoped>
.twitchEmbedClass {
  height: 100%;
}
.twitchEmbedClass > iframe {
}
.custom-modal {
  position: fixed;
  resize: both;
  overflow: hidden;
  border: 1px solid black;
  height: 360px;
  padding: 0;
  /*border: 1px solid #000;*/
  transition: all 0s ease !important;
  z-index: 9999;
}

.custom-modal-header {
  background: #333;
  color: white;
  padding: 10px;
  cursor: move;
  user-select: none;
  display: flex;
  justify-content: space-between;
}

.modal-close {
  font-size: 20px;
  cursor: pointer;
}
</style>
