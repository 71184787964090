<template>
  <div class="col mainContent">
    <div class="my-2 my-md-4">
      <div class="row d-flex m-2 m-md-0 p-4 p-lg-5 align-items-center rounded-3 border border-secondary">
        <div class="col-xxl-5 p-0 p-md-3 p-lg-5">
          <p class="text-light mb-0">Maximieren Sie Ihre Reichweite!</p>
          <h1 class="display-5 fw-bold lh-1">
            Serverbesitzer und Unternehmen,
            <span class="text-info">aufgepasst!</span>
          </h1>
          <p class="text-secondary">
            Für Serverbesitzer und Unternehmen eröffnet unsere Plattform eine einzigartige Gelegenheit, die Sichtbarkeit
            Ihrer Dienstleistungen und Produkte entscheidend zu steigern. Ob Sie eine Gaming-Community leiten oder ein
            etabliertes Unternehmen führen, unsere maßgeschneiderten Werbemöglichkeiten und unser umfassender Support
            stehen Ihnen zur Verfügung, um Ihre Botschaft gezielt an eine engagierte Zielgruppe zu vermitteln. Erfahren
            Sie, wie wir Sie dabei unterstützen können, Ihre Ziele zu erreichen und Ihr Unternehmen auf die nächste
            Stufe zu heben.
          </p>
          <img src="../assets/servercorp.png" class="img-fluid w-100 mt-4 border border-secondary" />
        </div>
        <div class="col-xxl-7 p-0 p-md-3 pt-4 pt-lg-0 p-lg-5 align-items-center">
          <div class="d-sm-flex">
            <div class="d-inline text-center w-50 p-2">
              <div class="p-2">
                <img src="../assets/mindestlaufzeit.png" class="img-fluid" />
                <h1 class="pt-3 m-0 fs-4 text-light">Monatlich Kündbar</h1>
                <p class="text-secondary">
                  Unser Service ist monatlich kündbar, sodass Sie die volle Flexibilität bei der Anzeigenplatzierung
                  behalten.
                </p>
              </div>
            </div>
            <div class="d-inline text-center w-50 p-2">
              <div class="p-2">
                <img src="../assets/euro.png" class="img-fluid" />
                <h1 class="pt-3 m-0 fs-4 text-light">Preiswerte Anzeigen</h1>
                <p class="text-secondary">
                  Wir bieten kostengünstige Anzeigenoptionen, die es Ihnen ermöglichen, Ihr Publikum zu erreichen, ohne
                  Ihr Budget zu sprengen.
                </p>
              </div>
            </div>
          </div>
          <div class="d-sm-flex">
            <div class="d-inline text-center w-50 p-2">
              <div class="p-2">
                <img src="../assets/gaming.png" class="img-fluid" />
                <h1 class="pt-3 m-0 fs-4 text-light">Gaming Community</h1>
                <p class="text-secondary">
                  Unsere Plattform ist das ideale Zuhause für Serverbesitzer und Unternehmen, die sich auf eine
                  engagierte Gaming-Community konzentrieren möchten.
                </p>
              </div>
            </div>
            <div class="d-inline text-center w-50 p-2">
              <div class="p-2">
                <img src="../assets/multiads.png" class="img-fluid" />
                <h1 class="pt-3 m-0 fs-4 text-light">Multi Ads</h1>
                <p class="text-secondary">
                  Mit unserer Multi Ads-Option können Sie gleichzeitig mehrere Anzeigen schalten, um eine breitere
                  Zielgruppe anzusprechen.
                </p>
              </div>
            </div>
          </div>
          <div class="d-sm-flex">
            <div class="d-inline text-center w-50 p-2">
              <div class="p-2">
                <img src="../assets/multimedia.png" class="img-fluid" />
                <h1 class="pt-3 m-0 fs-4 text-light">Multimedia Anzeige</h1>
                <p class="text-secondary">
                  Wir unterstützen Multimedia-Anzeigen, die Bilder, Videos und interaktive Elemente enthalten können, um
                  die Aufmerksamkeit Ihrer Zielgruppe zu gewinnen.
                </p>
              </div>
            </div>
            <div class="d-inline text-center w-50 p-2">
              <div class="p-2">
                <img src="../assets/ansprechpartner.png" class="img-fluid" />
                <h1 class="pt-3 m-0 fs-4 text-light">Full Support</h1>
                <p class="text-secondary">
                  Unser engagiertes Support-Team steht Ihnen jederzeit zur Verfügung, um sicherzustellen, dass Ihre
                  Anzeigenkampagne erfolgreich ist und alle Ihre Fragen beantwortet werden.
                </p>
              </div>
            </div>
          </div>
          <div class="mt-4 w-100 text-center">
            <p>Haben Sie noch weitere Fragen? Möchten Sie eine Anzeige platzieren?</p>
            <a href="mailto:info@rpstreams.de" class="btn btn-outline-info text-light">Kontakt aufnehmen</a>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="my-5">
      <div class="row d-flex m-2 m-md-0 p-4 p-lg-5 align-items-center rounded-3 border"></div>
    </div>-->
  </div>
</template>
<script>
export default {
  name: "StreamerCorp",
  data() {
    return {
      text: "Jetzt in dein Titel kopieren und direkt loslegen!",
    };
  },
};
</script>
