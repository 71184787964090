<template>
  <div class="col mainContent">
    <div class="d-flex m-0 p-0 pt-0 pt-lg-4 row">
      <div class="p-1 pb-0 flex-grow-1 col-sm-12 col-md-6 text-center text-md-start">
        <figure>
          <blockquote class="blockquote">
            <p>Top Clips</p>
          </blockquote>
          <figcaption class="blockquote-footer text-secondary">Top Clips der letzten 7 Tage</figcaption>
        </figure>
      </div>
      <div
        class="p-2 pb-0 col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-center d-flex align-items-center"
      >
        <div class="form-check form-switch">
          <label class="form-check-label text-secondary" for="flexSwitchCheckChecked"
            >Mehrere Einträge für Streamer erlauben</label
          >
          <input
            class="form-check-input shadow-none pointer"
            type="checkbox"
            role="switch"
            v-model="isChecked"
            @change="handleChange()"
            id="flexSwitchCheckChecked"
          />
        </div>
      </div>
    </div>
    <div class="row p-1 pt-0">
      <div
        class="col-xxl-3 col-lg-6 col-md-6 col-sm-12 pt-4 position-relative topClipsCard"
        v-for="(clip, index) in this.$store.getters.gtav_getTopClips"
        :key="clip.id"
        @click="setSelectedClip(clip)"
        data-bs-toggle="modal"
        data-bs-target="#topClips"
      >
        <img
          class="img-fluid w-100 border-bottom border-2 border-info"
          :src="clip.thumbnail_url"
          :alt="clip.broadcaster_name + ' - ' + clip.title"
        />
        <div
          class="ps-3 randomStreamerOverlay2 d-flex position-absolute top-50 start-0 border-end border-2 border-info"
        >
          <div class="p-1">
            <img :src="clip.profile_image_url" class="card-img-top rounded-circle" />
          </div>
          <div class="p-1 pe-3 align-self-center">
            <span class="clipViewCount text-secondary">{{ formatNumber(clip.view_count) }} Views</span>
            <h2 class="fs-6" :title="clip.broadcaster_name">
              <span class="text-info">{{ parseInt(index) + 1 }}.</span>
              {{
                clip.broadcaster_name.length > 17
                  ? clip.broadcaster_name.substring(0, 17) + "..."
                  : clip.broadcaster_name
              }}
            </h2>
          </div>
        </div>
        <div>
          <div class="d-flex p-0 pt-2 pb-0">
            <div class="flex-fill">
              <h1 class="fs-6 fw-bold">{{ clip.title }}</h1>
            </div>
          </div>
          <div class="d-flex p-0 pb-0 pt-0">
            <div class="flex-fill text-secondary">
              <p>
                Creator:
                <span class="fw-bold" :title="clip.creator_name">
                  {{ clip.creator_name > 20 ? clip.creator_name.substring(0, 20) + "..." : clip.creator_name }}</span
                >
              </p>
            </div>
            <div class="flex-fill text-end text-secondary">
              <p>{{ getDate(clip.created_at) }} - {{ clip.duration }} s</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal bd-example-modal-lg fade p-0 m-0"
    id="topClips"
    tabindex="-1"
    aria-labelledby="topClips"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered" style="max-width: 100%">
      <div class="modal-content bg-dark">
        <div class="modal-header mb-0 pb-0 border-0">
          Creator:&nbsp;
          <a :href="'https://twitch.tv/'+selectedClip.creator_name" class="text-info" target="_blank">
          <span class="fw-bold" :title="selectedClip.creator_name">
            {{
              selectedClip.creator_name > 20
                ? selectedClip.creator_name.substring(0, 20) + "..."
                : selectedClip.creator_name
            }}
          </span></a>
          <span> &nbsp;- {{ getDate(selectedClip.created_at) }}</span>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"></button>
        </div>
        <div class="modal-body">
          <iframe
            :src="`${selectedClip.embed_url}&parent=rpstreams.de`"
            frameborder="0"
            allowfullscreen="true"
            scrolling="no"
            height="480px"
            width="100%"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import gtav from "@/services/gtav/";
export default {
  name: "TopClips",
  data() {
    return {
      isChecked: false,
      selectedClip: {},
    };
  },
  async mounted() {
    gtav.getTopClips(false);
    let root = this;
    document.addEventListener('click', function(event) {
      
      if (event.target.matches('.modal')) {
    
      root.selectedClip = {};
      }
    });
  },
  methods: {
    closeModal(){
      //visuell wird es über bootstrap/css geschlossen
      //fix für audio:
      this.selectedClip = {};
    },
    setSelectedClip(c) {
      this.selectedClip = c;
    },
    formatNumber(number) {
      return gtav.formatNumber(number);
    },
    handleChange() {
      gtav.getTopClips(this.isChecked);
    },

    getDate(date) {
      date = new Date(date);
      const options = {
        day: "numeric",
        month: "short",
      };

      const formattedDate = date.toLocaleString("de-DE", options);
      return formattedDate;
    },
  },
};
</script>
