import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import TopClips from "@/views/TopClips.vue";
import TopServer from "@/views/TopServer.vue";
import ServerAnalyse from "@/views/ServerAnalyse.vue";
import ServerView from "@/views/ServerView.vue";
import SearchView from "@/views/SearchView.vue";
import StreamerCorp from "@/views/StreamerCorp.vue";
import ServerCorp from "@/views/ServerCorp.vue";
import FaqView from "@/views/FaqView.vue";
import ImpressumView from "@/views/ImpressumView.vue";
import DatenschutzView from "@/views/DatenschutzView.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { title: "RPstreams" },
  },
  {
    path: "/topclips",
    name: "clips",
    component: TopClips,
    meta: { title: "RPstreams - Top Clips" },
  },
  {
    path: "/topserver",
    name: "topserver",
    component: TopServer,
    meta: { title: "RPstreams - Top Server" },
  },

  {
    path: "/serveranalyse",
    name: "serveranalyse",
    component: ServerAnalyse,
    meta: { title: "RPstreams - Server-Analyse" },
  },
  {
    path: "/server/:server",
    name: "server",
    component: ServerView,
    meta: { title: "RPstreams - Server" },
  },
  {
    path: "/faq",
    name: "faq",
    component: FaqView,
    meta: { title: "RPstreams - Wissenwertes/FAQ" },
  },
  {
    path: "/streamercorp",
    name: "streamercorp",
    component: StreamerCorp,
    meta: { title: "RPstreams - Jetzt als Streamer mitmachen!" },
  },
  {
    path: "/search",
    name: "search",
    component: SearchView,
    meta: { title: "RPstreams - Suche" },
  },
  {
    path: "/servercorp",
    name: "servercorp",
    component: ServerCorp,
    meta: { title: "RPstreams - Jetzt Anzeigen schalten!" },
  },
  {
    path: "/impressum",
    name: "impressum",
    component: ImpressumView,
    meta: { title: "RPstreams - Impressum" },
  },
  {
    path: "/datenschutz",
    name: "datenschutz",
    component: DatenschutzView,
    meta: { title: "RPstreams - Datenschutz" },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

const DEFAULT_TITLE = "RPstreams";
router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
