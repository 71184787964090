<template>
  <div class="col mainContent">
    <div class="my-2 my-md-5">
      <div class="row d-flex m-2 m-md-0 p-4 p-lg-5 align-items-center rounded-3 border border-secondary">
        <div class="col-xxl-6 p-0 p-md-3 p-lg-5">
          <figure>
            <blockquote class="blockquote">
              <p class="text-info">FAQ - <span class="text-light">Häufig gestellte Fragen</span></p>
            </blockquote>
            <figcaption class="blockquote-footer">Alle nötigen Information zu GTA Roleplay.</figcaption>
          </figure>
          <h1 class="display-5 fw-bold lh-1">
            Deine ultimative Anleitung für
            <span class="text-info">Roleplay</span>
          </h1>
          <p class="text-secondary">
            Willkommen zur ultimativen Anleitung für GTA V Roleplay-Grundlagen! Wenn du diese Fragen verinnerlichst und
            die Schlüsselbegriffe vertraut machst, kannst du problemlos auf den meisten Servern spielen, ohne
            Regelverstöße oder Schwierigkeiten zu erleben. Roleplay in GTA V bietet ein fesselndes und immersives
            Erlebnis, aber es ist wichtig, die Grundlagen zu beherrschen. Diese Anleitung wird dir helfen, die
            Kernkonzepte zu verstehen und ein respektvoller, verantwortungsbewusster Spieler zu werden. Lass uns
            beginnen!
          </p>
        </div>
        <div class="col-xxl-6 p-0 p-md-3 p-lg-5">
          <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item bg-dark rounded-o">
              <h2 class="accordion-header">
                <button
                  class="shadow-none border-0 fs-6 fw-bold lead accordion-button collapsed bg-dark text-info"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Was ist GTA V RP / Roleplay?
                </button>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body bg-dark text-secondary">
                  <p>
                    &quot;GTA V Roleplay&quot; ist eine faszinierende Variante des beliebten Open-World-Spiels Grand
                    Theft Auto V (GTA V), bei der die Spieler in die Rollen verschiedener Charaktere schl&uuml;pfen und
                    in einer virtuellen Welt interagieren. Im Gegensatz zum traditionellen Gameplay, bei dem die Spieler
                    die vordefinierten Missionen des Spiels absolvieren, erm&ouml;glicht das Roleplay den Spielern, ihre
                    eigenen Geschichten zu entwickeln und in einer offenen Umgebung zu agieren.
                  </p>
                  <p>
                    Das Kernkonzept von GTA V Roleplay besteht darin, dass die Spieler die Kontrolle &uuml;ber ihre
                    Charaktere &uuml;bernehmen und sie in eine bestimmte Rolle oder ein bestimmtes Szenario versetzen.
                    Dies kann alles sein, von einem Polizisten &uuml;ber einen Gangster bis hin zu einem
                    Gesch&auml;ftsmann oder einem Arzt. Die Spieler agieren in einer lebendigen, von anderen Spielern
                    bewohnten Welt und k&ouml;nnen miteinander interagieren, um Geschichten zu erz&auml;hlen, Missionen
                    zu erstellen oder einfach die Umgebung zu erkunden.
                  </p>
                  <p>
                    Ein wichtiger Aspekt von GTA V Roleplay ist die Betonung auf realistisches Verhalten und die
                    Einhaltung von Regeln und Gesetzen, die f&uuml;r die gew&auml;hlte Rolle gelten. Dies erfordert oft
                    eine enge Zusammenarbeit und Kommunikation zwischen den Spielern, um realistische und fesselnde
                    Geschichten zu erstellen. Die Server, auf denen GTA V Roleplay gespielt wird, k&ouml;nnen
                    unterschiedliche Regelwerke und Schwerpunkte haben, von starkem Realismus bis hin zum Fokus auf den
                    Spa&szlig;faktor.
                  </p>
                  <p>
                    Insgesamt bietet GTA V Roleplay den Spielern die M&ouml;glichkeit, in eine virtuelle Welt
                    einzutauchen, in der sie ihre Kreativit&auml;t ausleben, Geschichten erz&auml;hlen und einzigartige
                    Erlebnisse schaffen k&ouml;nnen. Es hat sich zu einer bl&uuml;henden und vielf&auml;ltigen Community
                    entwickelt, die weiterhin w&auml;chst und die Grenzen dessen, was in einer Videospielumgebung
                    m&ouml;glich ist, erweitert.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="shadow-none border-0 accordion-button fs-6 fw-bold lead collapsed bg-dark text-info"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Wie ist GTA V RP / Roleplay entstanden?
                </button>
              </h2>
              <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body bg-dark text-secondary">
                  <p>
                    Die Geschichte des GTA V Roleplays in Deutschland ist eine faszinierende Reise durch die Entwicklung
                    einer lebendigen und vielf&auml;ltigen Gaming-Szene. Roleplay als Konzept existiert schon seit
                    &uuml;ber 15 Jahren in der Gaming-Welt und hat sich in verschiedenen Kategorien etabliert. Doch sein
                    eigentlicher Durchbruch begann in den USA mit GTA IV und einem besonderen Ansatz: dem &quot;Chat
                    Roleplay&quot;. Parallel dazu war Roleplay in Deutschland bereits in Survival-Games und vor allem in
                    &quot;Arma 3&quot; vertreten.
                  </p>
                  <p>
                    Die ersten namhaften Roleplay-Server in Deutschland begannen sich zwischen 2015 und 2017 zu
                    etablieren. Zu dieser Zeit gab es jedoch noch einige Herausforderungen zu bew&auml;ltigen. Die
                    Synchronisation zwischen den Spielern war nicht besonders gut, und die Server hatten oft
                    Begrenzungen von 32 bis 100 Personen. Einige Projekte, die &uuml;ber 300 Spieler anzogen, stellten
                    sogar 4 Server zur Verf&uuml;gung, wobei die Spieleraccounts zwischen den Servern synchronisiert
                    wurden.
                  </p>
                  <p>
                    W&auml;hrenddessen arbeiteten existierende und neue Launcher daran, eine bessere Synchronisation zu
                    erm&ouml;glichen, um der wachsenden Community ein reibungsloses Spielerlebnis zu bieten. Ein
                    gro&szlig;er Wendepunkt kam um das Jahr 2018 herum, als die Synchronisation in den jeweiligen
                    Spiellaunchern immer besser wurde. Mit dem Zuwachs an Entwicklern, die ihre Ressourcen zur freien
                    Nutzung zur Verf&uuml;gung stellten, gab es immer mehr neue Ideen und bessere Ressourcen, um
                    Projekte attraktiver zu gestalten.
                  </p>
                  <p>
                    Mit dem wachsenden Andrang auf die Roleplay-Szene sank leider in einigen Bereichen die
                    Qualit&auml;t. W&auml;hrend in den Anfangsphasen Wert auf realistisches und strenges Roleplay gelegt
                    wurde, verlagerte sich die Szene leicht in eine weniger strenge Richtung. Dennoch gibt es immer noch
                    Server, die sich auf ein stark realistisches Roleplay konzentrieren, w&auml;hrend andere mehr Wert
                    auf den Spa&szlig;faktor legen und ihre Regelwerke weniger streng gestalten.
                  </p>
                  <p>
                    Die Roleplay-Szene wird oft als eines der einzigartigsten Community-Spiele betrachtet. Viele
                    glauben, dass sie die Grundlage f&uuml;r eine Art Metaverse schafft und die ersten Schritte in diese
                    Richtung unternimmt. Roleplay bleibt ein Spiel, das ein einzigartiges Gameplay-Erlebnis bietet und
                    die Spieler in fantastische Welten eintauchen l&auml;sst. Die Geschichte des GTA V Roleplays in
                    Deutschland ist ein Beispiel daf&uuml;r, wie eine leidenschaftliche Community und engagierte
                    Entwickler eine Gaming-Szene gestalten und weiterentwickeln k&ouml;nnen.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="shadow-none border-0 accordion-button fs-6 fw-bold lead collapsed bg-dark text-info"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Was benötigt man um Roleplay spielen zu können?
                </button>
              </h2>
              <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body bg-dark text-secondary">
                  <p>
                    <span class="text-light font-monospace">Einen Gaming-Computer:</span>
                    Da GTA V ein anspruchsvolles Spiel ist, ist ein leistungsf&auml;higer Gaming-Computer erforderlich,
                    um es fl&uuml;ssig und ohne gr&ouml;&szlig;ere Probleme auszuf&uuml;hren. Dies umfasst einen
                    leistungsf&auml;higen Prozessor, ausreichend RAM und eine leistungsstarke Grafikkarte.
                  </p>
                  <p>
                    <span class="text-light font-monospace">Eine stabile Internetverbindung:</span>
                    Da GTA V Roleplay in einer Online-Umgebung gespielt wird, ist eine stabile und schnelle
                    Internetverbindung von entscheidender Bedeutung. Eine zuverl&auml;ssige Verbindung minimiert
                    Verz&ouml;gerungen und sorgt f&uuml;r ein reibungsloses Spielerlebnis.
                  </p>
                  <p>
                    <span class="text-light font-monospace">GTA V:</span> Um GTA V Roleplay spielen zu k&ouml;nnen,
                    ben&ouml;tigen Sie eine legale Kopie von Grand Theft Auto V. Diese kann &uuml;ber Plattformen wie
                    Steam, Epic Games Store oder direkt von Rockstar Games erworben werden. Die meisten GTA V
                    Roleplay-Server sind auf der Grundlage dieser legalen Kopien eingerichtet.
                  </p>
                  <p>
                    <span class="text-light font-monospace">GTA V Roleplay-Client:</span>
                    Einige GTA V Roleplay-Server erfordern die Verwendung eines speziellen Roleplay-Clients oder
                    Modifikationen, um auf den Servern spielen zu k&ouml;nnen. Diese k&ouml;nnen je nach Server
                    variieren und m&uuml;ssen h&auml;ufig separat heruntergeladen und installiert werden.
                  </p>
                  <p>
                    <span class="text-light font-monospace">Teamspeak oder Discord:</span>
                    Viele GTA V Roleplay-Server nutzen Kommunikations-Software wie Teamspeak oder Discord, um die
                    In-Game-Kommunikation zwischen den Spielern zu erleichtern. Es ist oft erforderlich, sich auf diesen
                    Plattformen anzumelden und den Server des jeweiligen Roleplay-Communities beizutreten.
                  </p>
                  <p>
                    <span class="text-light font-monospace">Ein Mikrofon:</span>
                    Da Kommunikation und Interaktion mit anderen Spielern ein zentraler Bestandteil des
                    Roleplay-Erlebnisses sind, ist ein funktionierendes Mikrofon notwendig, um in Gespr&auml;chen
                    teilnehmen zu k&ouml;nnen.
                  </p>
                  <p>
                    <span class="text-light font-monospace">Die Bereitschaft zur Einhaltung von Regeln:</span>
                    Jeder GTA V Roleplay-Server hat seine eigenen Regeln und Vorschriften, die eingehalten werden
                    m&uuml;ssen, um das Spielerlebnis f&uuml;r alle angenehm zu gestalten. Es ist wichtig, diese Regeln
                    zu respektieren und sich aktiv an der Gemeinschaft zu beteiligen.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="shadow-none border-0 accordion-button fs-6 fw-bold lead collapsed bg-dark text-info"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Ich habe ein PC, wie kann ich Roleplay spielen?
                </button>
              </h2>
              <div id="flush-collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body bg-dark text-secondary">
                  <p>
                    Es gibt drei wichtige Launcher für GTA V Roleplay: FiveM, Alt V und Rage MP. Du kannst den
                    jeweiligen Launcher von ihrer offiziellen Webseite herunterladen, und sie erkennen automatisch dein
                    Spiel. Wenn du den Launcher startest, siehst du eine Liste von Servern, auf denen du spielen kannst.
                    Einige Server erfordern eine "Whitelist", was bedeutet, dass du dich zuerst persönlich vorstellen
                    musst, sei es schriftlich oder mündlich über Discord.
                  </p>
                  <p>
                    Die einfachste Methode bietet FiveM, da die meisten Server eine "ingame Whitelist" haben, was
                    bedeutet, dass du nur den Launcher herunterladen und einem Server beitreten musst. Bei Rage MP und
                    Alt V musst du dich in der Regel zu 90% zuvor über Discord bewerben. Beachte, dass sobald du einen
                    Server betrittst, die Roleplay-Regeln gelten, und du dich sofort an diese Regeln halten musst.
                    Beachte auch, dass einige Server "Teamspeak" erfordern, um den Sprachchat zu synchronisieren. Die
                    IP-Adresse erfährst du normalerweise beim Verbinden zum Server oder im Discord des Servers.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="shadow-none border-0 accordion-button fs-6 fw-bold lead collapsed bg-dark text-info"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Was sollte ich beachten um Roleplay zu spielen?
                </button>
              </h2>
              <div id="flush-collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body bg-dark text-secondary">
                  <p>
                    "Die Grundregeln für GTA V Roleplay sind einfach zu verstehen, aber entscheidend für ein positives
                    und unterhaltsames Spielerlebnis. Erstens, sei respektvoll gegenüber deinen Mitspielern. Vermeide
                    Kraftausdrücke und beleidigende Sprache, um eine freundliche und respektvolle Atmosphäre zu
                    schaffen. Zweitens, sei rücksichtsvoll gegenüber anderen Spielern. Denke daran, dass du in direktem
                    Kontakt mit den Spielern stehst und ihre Erfahrung beeinflusst. Versuche, Konflikte in einem
                    respektvollen Rahmen zu lösen und vermeide unnötige Störungen.
                  </p>
                  <p>
                    Drittens, vermeide Raigen. Raigen bedeutet, dass du absichtlich andere Spieler störst oder das
                    Spielerlebnis anderer beeinträchtigst. Dies kann das Rollenspiel und die Atmosphäre negativ
                    beeinflussen. Halte dich an diese Grundregeln, um ein angenehmes GTA V Roleplay-Erlebnis für dich
                    und andere zu gewährleisten. Denke daran, dass Roleplay Spaß machen soll und dass respektvolles
                    Verhalten dazu beiträgt, eine positive Spielumgebung zu schaffen."
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="shadow-none border-0 accordion-button fs-6 fw-bold lead collapsed bg-dark text-info"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseSix"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Alle Begriffe auf einen Blick
                </button>
              </h2>
              <div id="flush-collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body bg-dark text-secondary">
                  <p>
                    <span class="text-light font-monospace">IC:</span> Dies bezieht sich auf die Situation, in der du
                    deinen frei erfundenen Charakter im Spiel verkörperst. Dieser Charakter hat keine Verbindung zu
                    deiner realen Person und ist rein fiktiv. Er darf keine Informationen haben, die du als reale Person
                    weißt, und er kann nur Informationen verwenden, die er im Spiel erlangt hat.
                  </p>
                  <p>
                    <span class="text-light font-monospace">OOC:</span> Dies bedeutet, dass du nicht mehr im Charakter,
                    also im Spiel, aktiv bist. Du befindest dich OOC, wenn du das Spiel verlässt, und du solltest
                    sicherstellen, dass du bei deinem erneuten Einloggen nicht OOC handelst, das heißt, dass du keine
                    Handlungen aus deinem realen Leben in das Spiel einbringst.
                  </p>
                  <p>
                    <span class="text-light font-monospace">Fail RP:</span>
                    Dies beschreibt Situationen, in denen Spieler Fehler begehen, sei es durch unrealistisches Verhalten
                    oder das Nichtbeachten der Regeln eines RP-Servers. Kurz gesagt, Fail RP tritt auf, wenn Spieler
                    gegen die Regeln und den Sinn des Rollenspiels verstoßen.
                  </p>
                  <p>
                    <span class="text-light font-monospace">Meta RP:</span>
                    Dies bezieht sich auf die Verwendung von OOC-Informationen für deinen IC/In-Game-Charakter, die
                    dieser Charakter eigentlich nicht wissen sollte. Zum Beispiel, wenn dir jemand außerhalb des Spiels
                    Informationen über eine Bedrohung mitteilt und du diese Informationen im Spiel verwendest, begehst
                    du Meta Gaming.
                  </p>
                  <p>
                    <span class="text-light font-monospace">RDM:</span> RDM steht für "Random Deathmatch" und bezieht
                    sich auf das unerlaubte und willkürliche Angreifen oder Töten anderer Spieler im Spiel, ohne einen
                    plausiblen In-Game-Grund. Dies verstößt normalerweise gegen die Regeln eines RP-Servers, da es das
                    Rollenspielerlebnis stört.
                  </p>
                  <p>
                    <span class="text-light font-monospace">VDM:</span> VDM steht für "Vehicle Deathmatch" und bezieht
                    sich auf das unerlaubte und willkürliche Verwenden von Fahrzeugen, um andere Spieler im Spiel
                    anzugreifen oder zu töten, ohne einen plausiblen In-Game-Grund.
                  </p>
                  <p>
                    <span class="text-light font-monospace">Combat Logging:</span>
                    "Combat Log" bezieht sich auf das unerlaubte Ausloggen oder Verlassen des Spiels während eines
                    laufenden Kampfes oder einer Konfrontation. In der Regel solltest du als Spieler während eines
                    Kampfes oder Konflikts im Spiel bleiben und die Situation auf eine angemessene Weise bewältigen,
                    anstatt einfach auszuloggen, um einer Auseinandersetzung zu entgehen.
                  </p>
                  <p>
                    <span class="text-light font-monospace">BUG using:</span>
                    Das Ausnutzen von Fehlern oder Systemlücken im Spiel, um unfaire Vorteile zu erlangen.
                  </p>
                  <p>
                    <span class="text-light font-monospace">Duping:</span>
                    "Duping" ist die unerlaubte Praxis des Vervielfältigens von Gegenständen oder Ressourcen im Spiel.
                    Dies geschieht oft durch die Ausnutzung von Fehlern oder Systemlücken im Spiel, um unfaire Vorteile
                    zu erlangen.
                  </p>
                  <p>
                    <span class="text-light font-monospace">Scripted RP:</span>
                    Das ist, wenn Spieler außerhalb des Spiels planen, was ihre Charaktere im Spiel tun werden, anstatt
                    spontan im Spiel zu handeln. In authentischem RP sollte die Handlung im Spiel (IC) entwickelt
                    werden, ohne vorherige Absprachen. Dies fördert ein immersiveres Erlebnis.
                  </p>
                  <p>
                    <span class="text-light font-monospace">Power RP:</span>
                    In Power RP geht es darum, sicherzustellen, dass man anderen Spielern im Spiel genügend
                    Handlungsspielraum lässt. Es ist wichtig, dass jeder Spieler genügend Freiheit hat, sein Rollenspiel
                    auszuleben und seine Charakterhandlungen zu gestalten, ohne von anderen eingeschränkt zu werden.
                  </p>
                  <p>
                    <span class="text-light font-monospace">Stream Sniping:</span>
                    Das ist, wenn jemand absichtlich den Livestream eines Spielers verfolgt, um im Spiel gezielt auf
                    diesen Spieler zu treffen oder mit ihm im Rollenspiel zu interagieren.
                  </p>
                  <p>
                    <span class="text-light font-monospace">Baiting:</span>
                    Das ist, wenn jemand absichtlich versucht, bestimmte Reaktionen oder Situationen aus anderen
                    herauszulocken, oft auf provokative Weise.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-5">
      <div class="row d-flex m-2 m-md-0 p-4 p-lg-5 align-items-center rounded-3 border border-secondary">
        <div class="col-xxl-4 p-0 p-md-3 p-lg-5">
          <h1 class="display-5 text-md-center fw-bold lh-1"><span class="text-info">rpstreams</span>.com</h1>
        </div>
        <div class="col-xxl-8 p-0 p-md-3 p-lg-5">
          <div class="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
            <div class="faqFeatured pt-4 p-md-4">
              <h4 class="fs-6 fw-bold lead">Unsere Philosophie</h4>
              <hr class="text-info divider w-25" />

              <p class="text-secondary">
                Unsere Philosophie bei RP-Streams ist einfach: Wir sind deine Anlaufstelle für alles rund um Roleplay.
                Auf unserer Seite findest du die aktuellen Server, sortiert nach Streams, die neuesten RP-Clips und
                faszinierende Statistiken. Wir sind leidenschaftlich darum bemüht, die Roleplay-Community zu
                unterstützen und zu fördern.
              </p>
            </div>
            <div class="faqFeatured pt-4 p-md-4">
              <h4 class="fs-6 fw-bold lead">Die Technik</h4>
              <hr class="text-info divider w-25" />

              <p class="text-secondary">
                In Bezug auf die Technik beziehen wir alle unsere Daten live aus der Twitch API. Wir führen alle zehn
                Sekunden eien Abfrage durch, um sicherzustellen, dass du stets unverfälschte Statistiken verfolgen
                kannst. Dies ermöglicht es dir auch, die Twitch-Chat-Optionen und weitere Funktionen mühelos zu nutzen
                und in Echtzeit auf dem Laufenden zu bleiben.
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-md-start mb-4 mb-lg-3">
            <div class="faqFeatured p-md-4">
              <h4 class="fs-6 fw-bold lead">Unser Ziel</h4>
              <hr class="text-info divider w-25" />
              <p class="text-secondary">
                Unser Ziel ist es, alle Roleplay-Server und die zugehörigen Statistiken für euch bereitzustellen. Wir
                streben danach, eine blühende Community zu schaffen, die in der Zukunft auch von kostenlosen
                Voting-Systemen, Chat-Optionen und vielem mehr profitieren kann. Unsere Vision ist es, eine lebendige
                Plattform für Roleplay-Enthusiasten zu schaffen, die von gemeinsamen Werten und kostenlosen Ressourcen
                getragen wird.
              </p>
            </div>
          </div>
          <div class="d-flex d-md-inline-flex justify-content-md-start mb-4 mb-lg-3 text-start text-md-center">
            <div class="faqFeatured p-md-4">
              <h4 class="fs-6 fw-bold lead">Joel T. (Joe)</h4>
              <hr class="divider m-2 w-25 mx-auto text-info" />
              <p class="text-secondary lh-1">Mitgründer, Back-End</p>
            </div>
          </div>
          <div class="d-flex d-md-inline-flex justify-content-md-start mb-4 mb-lg-3 text-start text-md-center">
            <div class="faqFeatured p-md-4">
              <h4 class="fs-6 fw-bold lead">Yusuf K. (MoinYusuf)</h4>
              <hr class="divider m-2 w-25 mx-auto text-info" />
              <p class="text-secondary lh-1">Mitgründer, Front-End</p>
            </div>
          </div>
          <div class="d-flex d-md-inline-flex justify-content-md-start mb-4 mb-lg-3 text-start text-md-center">
            <div class="faqFeatured p-md-4">
              <h4 class="fs-6 fw-bold lead">Vasili P. (Grentrox)</h4>
              <hr class="divider m-2 w-25 mx-auto text-info" />
              <p class="text-secondary lh-1">Vertrieb</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FaqView",
};
</script>
