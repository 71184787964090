<template>
  <div class="col mainContent">
    <div class="row p-2 pt-2">
      <div
        class="text-center"
        v-if="this.$store.getters.gtav_getSearchResults.length == 0 && this.$store.getters.gtav_getSearchError == null"
      >
        <h4 class="text-info fw-normal mt-5 pt-4 fs-4">❌ Keine Ergebnisse gefunden!</h4>
        <p class="text-secondary">
          Es wurde keine Ergebnisse in den Live-Streams und Clips gefunden.
          <br class="d-none d-sm-block" />Bitte versuche es mit einem anderen Suchbegriff.
        </p>
      </div>
      <div class="text-center" v-if="this.$store.getters.gtav_getSearchError != null">
        <h4 class="text-info fw-normal mt-5 pt-4 fs-4">⚠️ Fehlerhafte Suchanfrage!</h4>
        <p class="text-secondary">
          {{ this.$store.getters.gtav_getSearchError }}
        </p>
      </div>

      <div
        class="pt-4 pt-lg-3 streamerCard p-0 m-0 d-flex align-items-stretch"
        v-for="item in this.$store.getters.gtav_getSearchResults"
        :key="item.id"
      >
        <div
          class="p-2"
          data-bs-toggle="modal"
          data-bs-target="#topClips"
          v-if="item.type == 'clip'"
          :key="item.id"
          @click="setSelectedClip(item)"
        >
          <div class="mb-0 pb-0 position-relative">
            <img
              src="https://i.ibb.co/2sQkFXd/placeholder.jpg"
              alt=""
              class="placeholder-glow card-img-top rounded-0"
              v-if="!this.loadedUrls.includes(item.thumbnail_url)"
            />
            <img
              :src="item.thumbnail_url"
              :title="item.title"
              :alt="item.title"
              @load="this.loadedUrls.push(item.thumbnail_url)"
              class="card-img-top rounded-0"
            />
            <span
              class="z-3 position-absolute top-0 start-0 translate-middle badge badgePosition p-1 rounded-0 bg-info text-dark font-monospace fs-6"
              >CLIP
            </span>
            <div class="streamerViewer d-flex flex-column">
              <span class="streamerViewerCount">
                <img class="viewerIcon" src="https://i.ibb.co/zrtCSkT/group.png" />
                {{ formatNumber(item.view_count) }} Views
              </span>
            </div>
            <div class="d-flex pt-2 p-0">
              <div class="mx-auto p-1 m-0">
                <img
                  :title="item.broadcaster_name"
                  class="img-fluid rounded-circle mt-1"
                  :src="item.profile_image_url"
                />
              </div>
              <div class="col-10 p-0 m-0 ps-2">
                <p class="p-0 m-0 fw-bold" :title="item.title">
                  {{ formatTitle(item.title) }}
                </p>
                <h6 class="fs-6 text-secondary fw-normal" :title="item.broadcaster_name">
                  {{
                    item.broadcaster_name.length > 20
                      ? item.broadcaster_name.substring(0, 20) + "..."
                      : item.broadcaster_name
                  }}
                </h6>
                <p class="cardTags" :title="item.creator_name">Creator: {{ item.creator_name }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="p-2" @click="handleClick(item)" v-if="item.type == 'stream'" :key="item.id">
          <div class="mb-0 pb-0 position-relative">
            <img
              src="https://i.ibb.co/2sQkFXd/placeholder.jpg"
              alt=""
              class="placeholder-glow card-img-top rounded-0"
              v-if="!this.loadedUrls.includes(item.thumbnail_url)"
            />
            <img
              :src="item.thumbnail_url"
              :title="item.title"
              :alt="item.title"
              @load="this.loadedUrls.push(item.thumbnail_url)"
              class="card-img-top rounded-0"
            />
            <span
              class="z-3 position-absolute top-0 start-0 translate-middle badge badgePosition p-1 rounded-0 bg-danger font-monospace fs-6"
              >LIVE</span
            >
            <div class="streamerViewer d-flex flex-column">
              <span class="streamerViewerCount">
                <img class="viewerIcon" src="https://i.ibb.co/zrtCSkT/group.png" />
                {{ formatNumber(item.viewer_count) }}
              </span>
            </div>
            <div class="d-flex pt-2 p-0">
              <div class="mx-auto p-1 m-0">
                <img :title="item.user_name" class="img-fluid rounded-circle mt-1" :src="item.profile_image_url" />
              </div>
              <div class="col-10 p-0 m-0 ps-2">
                <p class="p-0 m-0 fw-bold" :title="item.title">
                  {{ formatTitle(item.title) }}
                </p>
                <h6 class="fs-6 text-secondary fw-normal" :title="item.user_name">
                  {{ item.user_name.length > 20 ? item.user_name.substring(0, 20) + "..." : item.user_name }}
                </h6>
                <p class="cardTags" :title="item.tags">
                  {{ item.tags.length > 25 ? item.tags.substring(0, 25) + "..." : item.tags }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="modal bd-example-modal-lg fade" id="topClips" tabindex="-1" aria-labelledby="topClips" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content bg-dark">
        <div class="modal-header mb-0 pb-0 border-0">
          Creator:&nbsp;
          <span class="fw-bold" :title="selectedClip.creator_name">
            {{
              selectedClip.creator_name > 20
                ? selectedClip.creator_name.substring(0, 20) + "..."
                : selectedClip.creator_name
            }}
          </span>
          <span> &nbsp;- {{ getDate(selectedClip.created_at) }}</span>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <iframe
            :src="`${selectedClip.embed_url}&parent=localhost`"
            frameborder="0"
            allowfullscreen="true"
            scrolling="no"
            height="100%"
            width="100%"
          ></iframe>
        </div>
      </div>
    </div>
  </div> -->
  <div
    class="modal bd-example-modal-lg fade p-0 m-0"
    id="topClips"
    tabindex="-1"
    aria-labelledby="topClips"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered" style="max-width: 100%">
      <div class="modal-content bg-dark">
        <div class="modal-header mb-0 pb-0 border-0">
          Creator:&nbsp;
          <span class="fw-bold" :title="selectedClip.creator_name">
            {{
              selectedClip.creator_name > 20
                ? selectedClip.creator_name.substring(0, 20) + "..."
                : selectedClip.creator_name
            }}
          </span>
          <span> &nbsp;- {{ getDate(selectedClip.created_at) }}</span>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <iframe
            :src="`${selectedClip.embed_url}&parent=rpstreams.de`"
            frameborder="0"
            allowfullscreen="true"
            scrolling="no"
            height="480px"
            width="100%"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import gtav from "@/services/gtav";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      page: 1,
      loadedUrls: [],
      selectedClip: {},
    };
  },
  async mounted() {
    if (this.$route.query.query) {
      gtav.searchAll(this.$route.query.query);
    }
  },
  unmounted() {},
  methods: {
    handleClick(stream) {
      /*
       new Twitch.Embed("twitch-embed", {
        width: "100%",
        height: "100%",
        channel: stream.user_name,
        layout: this.chatVisible ? "video-with-chat" : "video",
        theme: "dark",
        autoplay: true,
        allowfullscreen: true,
        // Only needed if this page is going to be embedded on other websites
        parent: ["localhost", "rpstreams.com", "rpstreams.de", "test.rpstreams.de"],
      });
      */
      //console.log(stream);
      this.selectedStreamer = stream.user_name;
      let modals = this.$store.getters.gtav_getStreamerModals;
      if (!modals.includes(stream)) {
        modals.push(stream);
        this.$store.dispatch("gtavSetStreamerModals", modals);
        setTimeout(() => {
          let modal = document.getElementById(`joeTemplate_${this.selectedStreamer}`);
          modal.style.zIndex = this.findHighestZIndex() + 1;
        }, 100);
      }
    },
    findHighestZIndex() {
      const elements = document.querySelectorAll(".custom-modal");
      let highestZIndex = 9999;

      elements.forEach((element) => {
        const zIndex = getComputedStyle(element).zIndex;
        const zIndexValue = parseInt(zIndex, 10);

        if (!isNaN(zIndexValue) && zIndexValue > highestZIndex) {
          highestZIndex = zIndexValue;
        }
      });

      return highestZIndex;
    },
    getDate(date) {
      date = new Date(date);
      const options = {
        day: "numeric",
        month: "short",
      };

      const formattedDate = date.toLocaleString("de-DE", options);
      return formattedDate;
    },
    setSelectedClip(c) {
      this.selectedClip = c;
    },
    formatNumber(number) {
      return gtav.formatNumber(number);
    },
    formatTitle(title) {
      if (title.length > 20) {
        title = title.substring(0, 20) + "...";
      }
      var words = title.split(" ");
      for (var j = 0; j < words.length; j++) {
        if (words[j].length > 10 && words[j] === words[j].toUpperCase()) {
          words[j] = words[j].toLowerCase();
        }
      }

      title = words.join(" ");

      return title;
    },
  },
};
</script>
