const axios = require("axios");
const api = process.env.VUE_APP_API_URL + "gtav";
const store = require("@/store").default;
let abortController;
async function refreshContent() {}

async function getServerList(ignoreOffline = false) {
  let res = await axios.get(`${api}/serverlist?ignoreOffline=${ignoreOffline}`);
  let currentList = store.getters.gtav_getServerList;
  const newList = res.data.message.list;

  res.data.message.allStreamers.totalviewer_count_old = currentList.allStreamers.totalviewer_count;
  res.data.message.allStreamers.liveCount_old = currentList.allStreamers.liveCount;

  // Compare the two lists
  if (currentList.list) {
    for (const newItem of newList) {
      const matchingCurrentItem = currentList.list.find((currentItem) => currentItem.serverName === newItem.serverName);

      if (matchingCurrentItem) {
        if (newItem.liveCount !== matchingCurrentItem.liveCount) {
          newItem.cssLive = true;
          newItem.cssLiveOld = matchingCurrentItem.liveCount;
          //console.log(newItem);
        } else {
          newItem.cssLive = false;
        }
        if (newItem.totalviewer_count !== matchingCurrentItem.totalviewer_count) {
          newItem.cssViewer = true;
          newItem.cssViewerOld = matchingCurrentItem.totalviewer_count;
          //console.log(newItem);
        } else {
          newItem.cssViewer = false;
        }
      }
    }
  }

  //console.log(res.data.message);
  if (res.data.status == "success" && ignoreOffline == false) {
    store.dispatch("gtavSetServerList", res.data.message);
    return res.data.message;
  } else {
    return res.data;
  }
}
async function getStreams(page, append = true) {
  console.log(page, append);
  if (!page) page = 1;
  // if (page == 1)

  let res = await axios.get(`${api}/streams?page=${page}`);
  if (res.data.status == "success") {
    if (append) {
      if (page == 1) {
        store.dispatch("gtavSetStreams", {
          currentPage: "1",
          totalPages: 10,
          totalResults: 100,
          streams: [],
        });
      }
      res.data.message.streams.unshift(...store.getters.gtav_getStreams.streams);
      store.dispatch("gtavSetStreams", res.data.message);
    } else {
      store.dispatch("gtavSetStreams", res.data.message);
    }
  }
}

async function getRandomStreams() {
  let res = await axios.get(`${api}/random_streams`);
  if (res.data.status == "success") {
    store.dispatch("gtavSetRandomStreams", res.data.message);
  }
}
async function getStreamsByServername(servername, page, append = true) {
  if (!page) page = 1;
  let res = await axios.get(`${api}/streams/${servername}?page=${page}`);
  if (res.data.status == "success") {
    if (append) {
      res.data.message.streams.unshift(...store.getters.gtav_getStreams.streams);
      store.dispatch("gtavSetStreams", res.data.message);
    } else {
      store.dispatch("gtavSetStreams", res.data.message);
    }
    //store.dispatch("gtavSetStreams", res.data.message);
  }
}
function formatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
async function getTopClips(allowDuplicates) {
  if (!allowDuplicates) allowDuplicates = false;
  
  let res = await axios.get(`${api}/topclips?allowDuplicates=${allowDuplicates}`);
  if (res.data.status == "success") {
    store.dispatch("gtavSetTopClips", res.data.message);
  }
}
async function getTopServers(sort, filter, primetime) {
  try {
   if(!abortController) abortController = new AbortController();
   else {
    abortController.abort();
    abortController = new AbortController();
   }
    store.dispatch('gtavSetLoadingState', { key: 'topservers', value: true });
    let res = await axios.get(`${api}/topservers?filter=${filter}&sort=${sort}&primetime=${primetime}`,{signal: abortController.signal});
    store.dispatch('gtavSetLoadingState', { key: 'topservers', value: false });
    if (res.data.status == "success") {
      store.dispatch("gtavSetTopServers", res.data.message);
    }
  } catch (error) {
    console.log("top server error",error)
  }

}
async function searchAll(query) {
  let res = await axios.get(`${api}/search?query=${query}`);
  if (res.data.status == "success") {
    store.dispatch("gtavSetSearchResults", res.data.message);
    store.dispatch("gtavSetSearchError", null);
  }
  if (res.data.status == "error") {
    store.dispatch("gtavSetSearchResults", []);
    store.dispatch("gtavSetSearchError", res.data.message);
  }
  return res.data;
}

async function getServerAnalytics(server, filter, all, query) {
  if (!all) all = false;
  if (query) all = true;
  store.dispatch('gtavSetLoadingState', { key: 'server_analytics', value: true });
  let res = await axios.get(`${api}/serverAnalytics?server=${server}&filter=${filter}&all=${all}&query=${query}`);
  store.dispatch('gtavSetLoadingState', { key: 'server_analytics', value: false });
  if (res.data.status == "success") {
    store.dispatch("gtavSetServerAnalytics", res.data.message);
  }
}

module.exports = {
  getServerList,
  refreshContent,
  getStreams,
  getRandomStreams,
  getStreamsByServername,
  formatNumber,
  getTopClips,
  getTopServers,
  searchAll,
  getServerAnalytics,
};
