<template>
  <div class="col mainContent">
    <div class="my-2 my-md-5">
      <div class="row d-flex m-2 m-md-0 p-4 p-lg-5 rounded-3 border border-secondary">
        <div class="col-xxl-3 p-0 p-md-3 p-lg-5">
          <figure>
            <blockquote class="blockquote">
              <p class="text-info">Angaben gemäß § 5 TMG</p>
            </blockquote>
            <figcaption class="blockquote-footer">Weitere Infos benötigt? Kontakt: info@rpstreams.de</figcaption>
          </figure>

          <p class="text-secondary">
            <span class="text-light">RP-Streams / rpstreams.com</span><br /><br />
            Yusuf Kursun<br />
            Andersenring 55-57<br />
            23560 Lübeck<br />

            E-Mail: info@rpstreams.de
          </p>
          <p class="text-secondary">
            Plattform der EU-Kommission zur Online-Streitbeilegung:<br />
            <a class="text-light" href="https://ec.europa.eu/consumers/odr" target="_blank"
              >https://ec.europa.eu/consumers/odr</a
            >
          </p>
        </div>
        <div class="col-xxl-9 p-0 p-md-3 p-lg-5">
          <div class="text-secondary fs-6">
            <h4 class="text-light" id="einleitung-ueberblick">Einleitung und Überblick</h4>
            <p>
              Wir haben diese Datenschutzerklärung (Fassung 07.08.2023-312557438) verfasst, um Ihnen gemäß der Vorgaben
              der
              <a
                class="text-light"
                href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=312557438#d1e2269-1-1"
                target="_blank"
                rel="noopener"
                >Datenschutz-Grundverordnung (EU) 2016/679</a
              >
              und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als
              Verantwortliche &#8211; und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &#8211;
              verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten
              Begriffe sind geschlechtsneutral zu verstehen.<br />
              <strong>Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.
            </p>
            <p>
              Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese
              Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich
              beschreiben. Soweit es der Transparenz förderlich ist, werden technische
              <strong>Begriffe leserfreundlich erklärt</strong>, Links zu weiterführenden Informationen geboten und
              <strong>Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache,
              dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine
              entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe,
              unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um
              Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und informativ und
              vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br />
              Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte
              verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf
              Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.
            </p>
            <h4 class="text-light" id="anwendungsbereich">Anwendungsbereich</h4>
            <p>
              Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und
              für alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit
              personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name,
              E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt
              dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder
              offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:
            </p>
            <ul>
              <li>alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
              <li>Social Media Auftritte und E-Mail-Kommunikation</li>
              <li>mobile Apps für Smartphones und andere Geräte</li>
            </ul>
            <p>
              <strong>Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene
              Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten wir außerhalb
              dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert
              informieren.
            </p>
            <h4 class="text-light" id="rechtsgrundlagen">Rechtsgrundlagen</h4>
            <p>
              In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen
              Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns
              ermöglichen, personenbezogene Daten zu verarbeiten.<br />
              Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS
              UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich
              online auf EUR-Lex, dem Zugang zum EU-Recht, unter
              <a class="text-light" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679"
                >https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679</a
              >
              nachlesen.
            </p>
            <p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
            <ol>
              <li>
                <strong>Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung
                gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer
                eingegebenen Daten eines Kontaktformulars.
              </li>
              <li>
                <strong>Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche
                Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen
                Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.
              </li>
              <li>
                <strong>Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen
                Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet
                Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.
              </li>
              <li>
                <strong>Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter
                Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung personenbezogener
                Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und
                wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.
              </li>
            </ol>
            <p>
              Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher
              Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine
              solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.
            </p>
            <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
            <ul>
              <li>
                In <strong>Österreich</strong> ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der
                Verarbeitung personenbezogener Daten (<strong>Datenschutzgesetz</strong>), kurz <strong>DSG</strong>.
              </li>
              <li>
                In <strong>Deutschland</strong> gilt das <strong>Bundesdatenschutzgesetz</strong>, kurz<strong>
                  BDSG</strong
                >.
              </li>
            </ul>
            <p>
              Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden
              Abschnitten darüber.
            </p>
            <h4 class="text-light" id="kontaktdaten-verantwortliche">Kontaktdaten des Verantwortlichen</h4>
            <p>
              Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten haben, finden Sie
              nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br />
              <span style="font-weight: 400"
                >Yusuf Kursun<br />
                Andersenring 55-57<br />
                23560 Lübeck</span
              >
            </p>
            <p>E-Mail: <a class="text-light" href="mailto:info@rpstreams.de">info@rpstreams.de</a></p>
            <p>
              Impressum:
              <a class="text-light" href="https://www.rpstreams.de/datenschutz.php"
                >https://www.rpstreams.de/datenschutz.php</a
              >
            </p>
            <h4 class="text-light" id="speicherdauer">Speicherdauer</h4>
            <p>
              Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer
              Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das
              bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr
              vorhanden ist. In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall
              des ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.
            </p>
            <p>
              Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen,
              werden die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.
            </p>
            <p>
              Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir
              weitere Informationen dazu haben.
            </p>
            <h4 class="text-light" id="rechte-dsgvo">Rechte laut Datenschutz-Grundverordnung</h4>
            <p>
              Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden Rechte, die Ihnen zustehen, damit es zu
              einer fairen und transparenten Verarbeitung von Daten kommt:
            </p>
            <ul>
              <li>
                Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten. Sollte
                das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen
                zu erfahren:
                <ul>
                  <li>zu welchem Zweck wir die Verarbeitung durchführen;</li>
                  <li>die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
                  <li>
                    wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, wie die Sicherheit
                    garantiert werden kann;
                  </li>
                  <li>wie lange die Daten gespeichert werden;</li>
                  <li>
                    das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem
                    Widerspruchsrecht gegen die Verarbeitung;
                  </li>
                  <li>
                    dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen Behörden finden Sie
                    weiter unten);
                  </li>
                  <li>die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
                  <li>
                    ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um zu einem
                    persönlichen Profil von Ihnen zu gelangen.
                  </li>
                </ul>
              </li>
              <li>
                Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten
                richtig stellen müssen, falls Sie Fehler finden.
              </li>
              <li>
                Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf Vergessenwerden“), was konkret
                bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.
              </li>
              <li>
                Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was bedeutet, dass wir die
                Daten nur mehr speichern dürfen aber nicht weiter verwenden.
              </li>
              <li>
                Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf
                Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.
              </li>
              <li>
                Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine Änderung der
                Verarbeitung mit sich bringt.
                <ul>
                  <li>
                    Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung
                    öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können Sie gegen
                    die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem
                    Widerspruch rechtlich nachkommen können.
                  </li>
                  <li>
                    Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art der
                    Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Direktmarketing
                    verwenden.
                  </li>
                  <li>
                    Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen diese Art der
                    Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Profiling verwenden.
                  </li>
                </ul>
              </li>
              <li>
                Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich auf einer
                automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.
              </li>
              <li>
                Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt, Sie können sich jederzeit bei der
                Datenschutzbehörde beschweren, wenn Sie der Meinung sind, dass die Datenverarbeitung von
                personenbezogenen Daten gegen die DSGVO verstößt.
              </li>
            </ul>
            <p>
              <strong>Kurz gesagt:</strong> Sie haben Rechte &#8211; zögern Sie nicht, die oben gelistete
              verantwortliche Stelle bei uns zu kontaktieren!
            </p>
            <p>
              Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
              datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der
              Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter
              <a class="text-light" href="https://www.dsb.gv.at/?tid=312557438" target="_blank" rel="noopener"
                >https://www.dsb.gv.at/</a
              >
              finden. In Deutschland gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere
              Informationen können Sie sich an die
              <a
                class="text-light"
                href="https://www.bfdi.bund.de/DE/Home/home_node.html"
                target="_blank"
                rel="noopener"
                >Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a
              >
              wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde zuständig:
            </p>
            <h4 class="text-light" id="schleswig-holstein-datenschutzbehoerde">
              Schleswig-Holstein Datenschutzbehörde
            </h4>
            <p>
              <strong>Landesbeauftragte für Datenschutz:</strong> Marit Hansen<br />
              <strong>Adresse: </strong>Holstenstraße 98, 24103 Kiel<br />
              <strong>Telefonnr.:</strong> 04 31/988-12 00<br />
              <strong>E-Mail-Adresse:</strong> mail@datenschutzzentrum.de<br />
              <strong>Website: </strong>
              <a
                class="text-light"
                href="https://www.datenschutzzentrum.de/?tid=312557438"
                target="_blank"
                rel="noopener"
                >https://www.datenschutzzentrum.de/</a
              >
            </p>
            <h4 class="text-light" id="datenuebertragung-drittlaender">Datenübertragung in Drittländer</h4>
            <p>
              Wir übertragen oder verarbeiten Daten nur dann in Länder außerhalb der EU (Drittländer), wenn Sie dieser
              Verarbeitung zustimmen, dies gesetzlich vorgeschrieben ist oder vertraglich notwendig und in jedem Fall
              nur soweit dies generell erlaubt ist. Ihre Zustimmung ist in den meisten Fällen der wichtigste Grund, dass
              wir Daten in Drittländern verarbeiten lassen. Die Verarbeitung personenbezogener Daten in Drittländern wie
              den USA, wo viele Softwarehersteller Dienstleistungen anbieten und Ihre Serverstandorte haben, kann
              bedeuten, dass personenbezogene Daten auf unerwartete Weise verarbeitet und gespeichert werden.
            </p>
            <p>
              Wir weisen ausdrücklich darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein
              angemessenes Schutzniveau für den Datentransfer in die USA besteht. Die Datenverarbeitung durch US-Dienste
              (wie beispielsweise Google Analytics) kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert
              verarbeitet und gespeichert werden. Ferner können gegebenenfalls US-amerikanische staatliche Behörden
              Zugriff auf einzelne Daten nehmen. Zudem kann es vorkommen, dass erhobene Daten mit Daten aus anderen
              Diensten desselben Anbieters, sofern Sie ein entsprechendes Nutzerkonto haben, verknüpft werden. Nach
              Möglichkeit versuchen wir Serverstandorte innerhalb der EU zu nutzen, sofern das angeboten wird.
            </p>
            <p>
              Wir informieren Sie an den passenden Stellen dieser Datenschutzerklärung genauer über Datenübertragung in
              Drittländer, sofern diese zutrifft.
            </p>
            <h4 class="text-light" id="sicherheit-datenverarbeitung">Sicherheit der Datenverarbeitung</h4>
            <p>
              Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen
              umgesetzt. Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten. Dadurch
              machen wir es im Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren Daten auf
              persönliche Informationen schließen können.
            </p>
            <p>
              Art. 25 DSGVO spricht hier von &#8220;Datenschutz durch Technikgestaltung und durch datenschutzfreundliche
              Voreinstellungen&#8221; und meint damit, dass man sowohl bei Software (z. B. Formularen) also auch
              Hardware (z. B. Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen setzt. Im
              Folgenden gehen wir, falls erforderlich, noch auf konkrete Maßnahmen ein.
            </p>
            <h4 class="text-light" id="tls-verschluesselung-https">TLS-Verschlüsselung mit https</h4>
            <p>
              TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext
              Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im
              Internet zu übertragen.<br />
              Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver
              abgesichert ist &#8211; niemand kann &#8220;mithören&#8221;.
            </p>
            <p>
              Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen den Datenschutz durch
              Technikgestaltung (<a
                class="text-light"
                href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=312557438"
                target="_blank"
                rel="noopener"
                >Artikel 25 Absatz 1 DSGVO</a
              >). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren
              Datenübertragung im Internet, können wir den Schutz vertraulicher Daten sicherstellen.<br />
              Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol
              <img
                role="img"
                src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg"
                width="17"
                height="18"
              />
              links oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des
              Schemas https (anstatt http) als Teil unserer Internetadresse.<br />
              Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach
              &#8220;Hypertext Transfer Protocol Secure wiki&#8221; um gute Links zu weiterführenden Informationen zu
              erhalten.
            </p>
            <h4 class="text-light" id="cookies">Cookies</h4>
            <table border="1" cellpadding="15">
              <tbody>
                <tr>
                  <td>
                    <strong>Cookies Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim
                    Hersteller der Software, der das Cookie setzt.<br />
                    &#x1f4d3; Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie. Mehr Details dazu finden Sie
                    weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br />
                    &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden bis hin zu Jahren
                    variieren<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f
                    DSGVO (Berechtigte Interessen)
                  </td>
                </tr>
              </tbody>
            </table>
            <h4 class="text-light">Was sind Cookies?</h4>
            <p>
              Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.<br />
              Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende
              Datenschutzerklärung besser verstehen.
            </p>
            <p>
              Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind
              beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Websites
              speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.
            </p>
            <p>
              Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Websites
              verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere
              Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer
              gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem &#8220;Hirn&#8221;
              Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines
              Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.
            </p>
            <p>
              Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche
              Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“
              Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen
              die Einstellung, die Sie gewohnt sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen
              wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.
            </p>
            <p>
              Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem
              Webserver. Dabei fordert der Webbrowser eine Website an und erhält vom Server ein Cookie zurück, welches
              der Browser erneut verwendet, sobald eine andere Seite angefordert wird.
            </p>
            <p>
              <img
                role="img"
                src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg"
                alt="HTTP Cookie Interaktion zwischen Browser und Webserver"
                width="100%"
              />
            </p>
            <p>
              Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von
              unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z.B. Google Analytics)
              erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die
              Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine
              Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht
              auf Informationen Ihres PCs zugreifen.
            </p>
            <p>So können zum Beispiel Cookie-Daten aussehen:</p>
            <p>
              <strong>Name:</strong> _ga<br />
              <strong>Wert:</strong> GA1.2.1326744211.152312557438-9<br />
              <strong>Verwendungszweck:</strong> Unterscheidung der Websitebesucher<br />
              <strong>Ablaufdatum:</strong> nach 2 Jahren
            </p>
            <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
            <ul>
              <li>Mindestens 4096 Bytes pro Cookie</li>
              <li>Mindestens 50 Cookies pro Domain</li>
              <li>Mindestens 3000 Cookies insgesamt</li>
            </ul>
            <h4 class="text-light">Welche Arten von Cookies gibt es?</h4>
            <p>
              Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in
              den folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die
              verschiedenen Arten von HTTP-Cookies eingehen.
            </p>
            <p>Man kann 4 Arten von Cookies unterscheiden:</p>
            <p>
              <strong>Unerlässliche Cookies<br /> </strong>Diese Cookies sind nötig, um grundlegende Funktionen der
              Website sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb
              legt, dann auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der
              Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.
            </p>
            <p>
              <strong>Zweckmäßige Cookies<br /> </strong>Diese Cookies sammeln Infos über das Userverhalten und ob der
              User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das
              Verhalten der Website bei verschiedenen Browsern gemessen.
            </p>
            <p>
              <strong>Zielorientierte Cookies<br /> </strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit.
              Beispielsweise werden eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.
            </p>
            <p>
              <strong>Werbe-Cookies<br /> </strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu
              dem User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.
            </p>
            <p>
              Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser Cookiearten Sie
              zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.
            </p>
            <p>
              Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir
              <a class="text-light" href="https://datatracker.ietf.org/doc/html/rfc6265"
                >https://datatracker.ietf.org/doc/html/rfc6265</a
              >, dem Request for Comments der Internet Engineering Task Force (IETF) namens &#8220;HTTP State Management
              Mechanism&#8221;.
            </p>
            <h4 class="text-light">Zweck der Verarbeitung über Cookies</h4>
            <p>
              Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw.
              beim Hersteller der Software, die das Cookie setzt.
            </p>
            <h4 class="text-light">Welche Daten werden verarbeitet?</h4>
            <p>
              Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten in Cookies gespeichert
              werden, kann man leider nicht verallgemeinern, aber wir werden Sie im Rahmen der folgenden
              Datenschutzerklärung über die verarbeiteten bzw. gespeicherten Daten informieren.
            </p>
            <h4 class="text-light">Speicherdauer von Cookies</h4>
            <p>
              Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert. Manche Cookies werden
              nach weniger als einer Stunde gelöscht, andere können mehrere Jahre auf einem Computer gespeichert
              bleiben.
            </p>
            <p>
              Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren Browser sämtliche Cookies
              jederzeit manuell löschen (siehe auch unten &#8220;Widerspruchsrecht&#8221;). Ferner werden Cookies, die
              auf einer Einwilligung beruhen, spätestens nach Widerruf Ihrer Einwilligung gelöscht, wobei die
              Rechtmäßigkeit der Speicherung bis dahin unberührt bleibt.
            </p>
            <h4 class="text-light">Widerspruchsrecht &#8211; wie kann ich Cookies löschen?</h4>
            <p>
              Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder
              welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren
              oder nur teilweise zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle
              anderen Cookies zulassen.
            </p>
            <p>
              Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie
              Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:
            </p>
            <p>
              <a
                class="text-light"
                href="https://support.google.com/chrome/answer/95647?tid=312557438"
                target="_blank"
                rel="noopener noreferrer"
                >Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a
              >
            </p>
            <p>
              <a
                class="text-light"
                href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=312557438"
                target="_blank"
                rel="noopener noreferrer"
                >Safari: Verwalten von Cookies und Websitedaten mit Safari</a
              >
            </p>
            <p>
              <a
                class="text-light"
                href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=312557438"
                target="_blank"
                rel="noopener noreferrer"
                >Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a
              >
            </p>
            <p>
              <a
                class="text-light"
                href="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d?tid=312557438"
                >Internet Explorer: Löschen und Verwalten von Cookies</a
              >
            </p>
            <p>
              <a
                class="text-light"
                href="https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-l%C3%B6schen-63947406-40ac-c3b8-57b9-2a946a29ae09?tid=312557438"
                >Microsoft Edge: Löschen und Verwalten von Cookies</a
              >
            </p>
            <p>
              Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie
              immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
              entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am
              besten Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder &#8220;Cookies
              deaktivieren Chrome&#8221; im Falle eines Chrome Browsers.
            </p>
            <h4 class="text-light">Rechtsgrundlage</h4>
            <p>
              Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von
              Cookies eine <strong>Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen verlangt. Innerhalb
              der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In
              Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes
              (TKG). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen
              erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).
            </p>
            <p>
              Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt, bestehen
              <strong>berechtigte Interessen</strong> (Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten Fällen
              wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme Benutzererfahrung
              bescheren und dafür sind bestimmte Cookies oft unbedingt notwendig.
            </p>
            <p>
              Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im Falle Ihrer
              Einwilligung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.
            </p>
            <p>
              In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies informiert, sofern
              eingesetzte Software Cookies verwendet.
            </p>
            <h4 class="text-light" id="webhosting-einleitung">Webhosting Einleitung</h4>
            <table border="1" cellpadding="15">
              <tbody>
                <tr>
                  <td>
                    <strong>Webhosting Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br />
                    &#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und
                    weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting
                    Provider.<br />
                    &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)
                  </td>
                </tr>
              </tbody>
            </table>
            <h4 class="text-light">Was ist Webhosting?</h4>
            <p>
              Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &#8211; auch personenbezogene Daten
              &#8211; automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst
              sparsam und nur mit Begründung verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit aller
              Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite
              (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.
            </p>
            <p>
              Wenn Sie eine Website auf einem Computer, Tablet oder Smartphone ansehen möchten, verwenden Sie dafür ein
              Programm, das sich Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome,
              Microsoft Edge, Mozilla Firefox und Apple Safari. Wir sagen kurz Browser oder Webbrowser dazu.
            </p>
            <p>
              Um die Website anzuzeigen, muss sich der Browser zu einem anderen Computer verbinden, wo der Code der
              Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige
              Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den Providern, übernommen wird. Diese
              bieten Webhosting an und sorgen damit für eine verlässliche und fehlerfreie Speicherung der Daten von
              Websites. Eine ganze Menge Fachbegriffe, aber bitte bleiben Sie dran, es wird noch besser!
            </p>
            <p>
              Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Tablet oder Smartphone) und
              während der Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten
              kommen. Einerseits speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang
              speichern, um einen ordentlichen Betrieb zu gewährleisten.
            </p>
            <p>
              Ein Bild sagt mehr als tausend Worte, daher zeigt folgende Grafik zur Veranschaulichung das Zusammenspiel
              zwischen Browser, dem Internet und dem Hosting-Provider.
            </p>
            <p>
              <img
                role="img"
                src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg"
                alt="Browser und Webserver"
                width="100%"
              />
            </p>
            <h4 class="text-light">Warum verarbeiten wir personenbezogene Daten?</h4>
            <p>Die Zwecke der Datenverarbeitung sind:</p>
            <ol>
              <li>Professionelles Hosting der Website und Absicherung des Betriebs</li>
              <li>zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
              <li>
                Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. zur Strafverfolgung
                bzw. Verfolgung von Ansprüchen
              </li>
            </ol>
            <h4 class="text-light">Welche Daten werden verarbeitet?</h4>
            <p>
              Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf
              dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie
            </p>
            <ul>
              <li>die komplette Internetadresse (URL) der aufgerufenen Webseite</li>
              <li>Browser und Browserversion (z. B. Chrome 87)</li>
              <li>das verwendete Betriebssystem (z. B. Windows 10)</li>
              <li>
                die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B.
                <a
                  class="text-light"
                  href="https://www.beispielquellsite.de/vondabinichgekommen/"
                  target="_blank"
                  rel="follow noopener"
                  >https://www.beispielquellsite.de/vondabinichgekommen/</a
                >)
              </li>
              <li>
                den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und
                194.23.43.121)
              </li>
              <li>Datum und Uhrzeit</li>
              <li>in Dateien, den sogenannten Webserver-Logfiles</li>
            </ul>
            <h4 class="text-light">Wie lange werden Daten gespeichert?</h4>
            <p>
              In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht. Wir
              geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
              rechtswidrigem Verhalten von Behörden eingesehen werden.
            </p>
            <p>
              <strong>Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf
              speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht ohne
              Zustimmung weiter!
            </p>
            <h4 class="text-light">Rechtsgrundlage</h4>
            <p>
              Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art.
              6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting
              bei einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich präsentieren
              und Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu können.
            </p>
            <p>
              Zwischen uns und dem Hosting-Provider besteht in der Regel ein Vertrag über die Auftragsverarbeitung gemäß
              Art. 28 f. DSGVO, der die Einhaltung von Datenschutz gewährleistet und Datensicherheit garantiert.
            </p>
            <h4 class="text-light" id="web-analytics-einleitung">Web Analytics Einleitung</h4>
            <table border="1" cellpadding="15">
              <tbody>
                <tr>
                  <td>
                    <strong>Web Analytics Datenschutzerklärung Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br />
                    &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe,
                    Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen
                    enthalten. Mehr Details dazu finden Sie beim jeweils eingesetzten Web Analytics Tool.<br />
                    &#x1f4c5; Speicherdauer: abhängig vom eingesetzten Web-Analytics-Tool<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f
                    DSGVO (Berechtigte Interessen)
                  </td>
                </tr>
              </tbody>
            </table>
            <h4 class="text-light">Was ist Web Analytics?</h4>
            <p>
              Wir verwenden auf unserer Website Software zur Auswertung des Verhaltens der Website-Besucher, kurz Web
              Analytics oder Web-Analyse genannt. Dabei werden Daten gesammelt, die der jeweilige Analytic-Tool-Anbieter
              (auch Trackingtool genannt) speichert, verwaltet und verarbeitet. Mit Hilfe der Daten werden Analysen über
              das Nutzerverhalten auf unserer Website erstellt und uns als Websitebetreiber zur Verfügung gestellt.
              Zusätzlich bieten die meisten Tools verschiedene Testmöglichkeiten an. So können wir etwa testen, welche
              Angebote oder Inhalte bei unseren Besuchern am besten ankommen. Dafür zeigen wir Ihnen für einen
              begrenzten Zeitabschnitt zwei verschiedene Angebote. Nach dem Test (sogenannter A/B-Test) wissen wir,
              welches Produkt bzw. welcher Inhalt unsere Websitebesucher interessanter finden. Für solche Testverfahren,
              wie auch für andere Analytics-Verfahren, können auch Userprofile erstellt werden und die Daten in Cookies
              gespeichert werden.
            </p>
            <h4 class="text-light">Warum betreiben wir Web Analytics?</h4>
            <p>
              Mit unserer Website haben wir ein klares Ziel vor Augen: wir wollen für unsere Branche das beste
              Webangebot auf dem Markt liefern. Um dieses Ziel zu erreichen, wollen wir einerseits das beste und
              interessanteste Angebot bieten und andererseits darauf achten, dass Sie sich auf unserer Website rundum
              wohlfühlen. Mit Hilfe von Webanalyse-Tools können wir das Verhalten unserer Websitebesucher genauer unter
              die Lupe nehmen und dann entsprechend unser Webangebot für Sie und uns verbessern. So können wir
              beispielsweise erkennen wie alt unsere Besucher durchschnittlich sind, woher sie kommen, wann unsere
              Website am meisten besucht wird oder welche Inhalte oder Produkte besonders beliebt sind. All diese
              Informationen helfen uns die Website zu optimieren und somit bestens an Ihre Bedürfnisse, Interessen und
              Wünsche anzupassen.
            </p>
            <h4 class="text-light">Welche Daten werden verarbeitet?</h4>
            <p>
              Welche Daten genau gespeichert werden, hängt natürlich von den verwendeten Analyse-Tools ab. Doch in der
              Regel wird zum Beispiel gespeichert, welche Inhalte Sie auf unserer Website ansehen, auf welche Buttons
              oder Links Sie klicken, wann Sie eine Seite aufrufen, welchen Browser sie verwenden, mit welchem Gerät
              (PC, Tablet, Smartphone usw.) Sie die Website besuchen oder welches Computersystem Sie verwenden. Wenn Sie
              damit einverstanden waren, dass auch Standortdaten erhoben werden dürfen, können auch diese durch den
              Webanalyse-Tool-Anbieter verarbeitet werden.
            </p>
            <p>
              Zudem wird auch Ihre IP-Adresse gespeichert. Gemäß der Datenschutz-Grundverordnung (DSGVO) sind
              IP-Adressen personenbezogene Daten. Ihre IP-Adresse wird allerdings in der Regel pseudonymisiert (also in
              unkenntlicher und gekürzter Form) gespeichert. Für den Zweck der Tests, der Webanalyse und der
              Weboptimierung werden grundsätzlich keine direkten Daten, wie etwa Ihr Name, Ihr Alter, Ihre Adresse oder
              Ihre E-Mail-Adresse gespeichert. All diese Daten werden, sofern sie erhoben werden, pseudonymisiert
              gespeichert. So können Sie als Person nicht identifiziert werden.
            </p>
            <p>
              Das folgende Beispiel zeigt schematisch die Funktionsweise von Google Analytics als Beispiel für
              client-basiertes Webtracking mit Java-Script-Code.
            </p>
            <p>
              <img
                role="img"
                src="https://www.adsimple.at/wp-content/uploads/2021/04/google-analytics-dataflow.svg"
                alt="Schematischer Datenfluss bei Google Analytics"
                width="100%"
              />
            </p>
            <p>
              Wie lange die jeweiligen Daten gespeichert werden, hängt immer vom Anbieter ab. Manche Cookies speichern
              Daten nur für ein paar Minuten bzw. bis Sie die Website wieder verlassen, andere Cookies können Daten über
              mehrere Jahre speichern.
            </p>
            <h4 class="text-light">
              <span
                data-sheets-value='{"1":2,"2":"Wo und wie lange werden Daten gespeichert?"}'
                data-sheets-userformat='{"2":769,"3":{"1":0},"11":4,"12":0}'
                >Dauer der Datenverarbeitung</span
              >
            </h4>
            <p>
              Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen
              dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung
              unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es, wie zum Beispiel im Fall von
              Buchhaltung, gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch überschritten werden.
            </p>
            <h4 class="text-light">Widerspruchsrecht</h4>
            <p>
              Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw.
              Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere
              Opt-Out-Funktionen. Zum Beispiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in
              Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.
            </p>
            <h4 class="text-light">Rechtsgrundlage</h4>
            <p>
              Der Einsatz von Web-Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup
              eingeholt haben. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong>
              die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch
              Web-Analytics Tools vorkommen kann, dar.
            </p>
            <p>
              Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, das Verhalten der
              Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe
              von Web-Analytics erkennen wir Fehler der Website, können Attacken identifizieren und die
              Wirtschaftlichkeit verbessern. Die Rechtsgrundlage dafür ist
              <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen die Tools gleichwohl nur
              ein, soweit sie eine Einwilligung erteilt haben.
            </p>
            <p>
              Da bei Web-Analytics-Tools Cookies zum Einsatz kommen, empfehlen wir Ihnen auch das Lesen unserer
              allgemeinen Datenschutzerklärung zu Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und
              verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.
            </p>
            <p>
              Informationen zu speziellen Web-Analytics-Tools, erhalten Sie &#8211; sofern vorhanden &#8211; in den
              folgenden Abschnitten.
            </p>
            <h4 class="text-light" id="google-analytics-datenschutzerklaerung">
              Google Analytics Datenschutzerklärung
            </h4>
            <table border="1" cellpadding="15">
              <tbody>
                <tr>
                  <td>
                    <strong>Google Analytics Datenschutzerklärung Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br />
                    &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe,
                    Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten und Klickverhalten enthalten. Mehr
                    Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
                    &#x1f4c5; Speicherdauer: individuell einstellbar, standardmäßig speichert Google Analytics 4 Daten
                    für 14 Monate<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f
                    DSGVO (Berechtigte Interessen)
                  </td>
                </tr>
              </tbody>
            </table>
            <h4 class="text-light">Was ist Google Analytics?</h4>
            <p>
              Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics in der Version Google
              Analytics 4 (GA4) des amerikanischen Unternehmens Google Inc. Für den europäischen Raum ist das
              Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste
              verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen auf unserer Website. Durch die
              Kombination aus verschiedenen Technologien wie Cookies, Geräte-IDs und Anmeldeinformationen, können Sie
              als User aber über verschiedene Geräte hinweg identifiziert werden. Dadurch können Ihre Handlungen auch
              plattformübergreifend analysiert werden.
            </p>
            <p>
              Wenn Sie beispielsweise einen Link anklicken, wird dieses Ereignis in einem Cookie gespeichert und an
              Google Analytics versandt. Mithilfe der Berichte, die wir von Google Analytics erhalten, können wir unsere
              Website und unseren Service besser an Ihre Wünsche anpassen. Im Folgenden gehen wir näher auf das
              Tracking-Tool ein und informieren Sie vor allem darüber, welche Daten verarbeitet werden und wie Sie das
              verhindern können.
            </p>
            <p>
              Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient. Basis dieser
              Messungen und Analysen ist eine pseudonyme Nutzeridentifikationsnummer. Diese Nummer beinhaltet keine
              personenbezogenen Daten wie Name oder Adresse, sondern dient dazu, Ereignisse einem Endgerät zuzuordnen.
              GA4 nutzt ein ereignisbasiertes Modell, das detaillierte Informationen zu Userinteraktionen wie etwa
              Seitenaufrufe, Klicks, Scrollen, Conversion-Ereignisse erfasst. Zudem wurden in GA4 auch verschiedene
              maschinelle Lernfunktionen eingebaut, um das Nutzerverhalten und gewissen Trends besser zu verstehen. GA4
              setzt mit Hilfe maschineller Lernfunktionen auf Modellierungen. Das heißt auf Grundlage der erhobenen
              Daten können auch fehlende Daten hochgerechnet werden, um damit die Analyse zu optimieren und auch um
              Prognosen geben zu können.
            </p>
            <p>
              Damit Google Analytics grundsätzlich funktioniert, wird ein Tracking-Code in den Code unserer Website
              eingebaut. Wenn Sie unsere Website besuchen, zeichnet dieser Code verschiedene Ereignisse auf, die Sie auf
              unserer Website ausführen. Mit dem ereignisbasierten Datenmodell von GA4 können wir als Websitebetreiber
              spezifische Ereignisse definieren und verfolgen, um Analysen von Userinteraktionen zu erhalten. Somit
              können neben allgemeinen Informationen wie Klicks oder Seitenaufrufe auch spezielle Ereignisse, die für
              unser Geschäft wichtig sind, verfolgt werden. Solche speziellen Ereignisse können zum Beispiel das
              Absenden eines Kontaktformulars oder der Kauf eines Produkts sein.
            </p>
            <p>
              Sobald Sie unsere Website verlassen, werden diese Daten an die Google-Analytics-Server gesendet und dort
              gespeichert.
            </p>
            <p>
              Google verarbeitet die Daten und wir bekommen Berichte über Ihr Userverhalten. Dabei kann es sich unter
              anderem um folgende Berichte handeln:
            </p>
            <ul>
              <li>
                Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User besser kennen und wissen genauer,
                wer sich für unser Service interessiert.
              </li>
              <li>
                Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung leichter analysieren und
                verbessern.
              </li>
              <li>
                Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen darüber, wie wir mehr
                Menschen für unseren Service begeistern können.
              </li>
              <li>
                Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website interagieren. Wir können
                nachvollziehen welchen Weg Sie auf unserer Seite zurücklegen und welche Links Sie anklicken.
              </li>
              <li>
                Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie aufgrund einer Marketing-Botschaft
                eine gewünschte Handlung ausführen. Zum Beispiel, wenn Sie von einem reinen Websitebesucher zu einem
                Käufer oder Newsletter-Abonnent werden. Mithilfe dieser Berichte erfahren wir mehr darüber, wie unsere
                Marketing-Maßnahmen bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.
              </li>
              <li>
                Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website passiert. Zum Beispiel
                sehen wir wie viele User gerade diesen Text lesen.
              </li>
            </ul>
            <p>
              Neben den oben genannten Analyseberichten bietet Google Analytics 4 unter anderem auch folgende Funktionen
              an:
            </p>
            <ul>
              <li>
                Ereignisbasiertes Datenmodell: Dieses Modell erfasst ganz spezifische Ereignisse, die auf unserer
                Website stattfinden können. Zum Beispiel das Abspielen eines Videos, der Kauf eines Produkts oder das
                Anmelden zu unserem Newsletter.
              </li>
              <li>
                Erweiterte Analysefunktionen: Mit diesen Funktionen können wir Ihr Verhalten auf unserer Website oder
                gewisse allgemeine Trends noch besser verstehen. So können wir etwa Usergruppen segmentieren,
                Vergleichsanalysen von Zielgruppen machen oder Ihren Weg bzw. Pfad auf unserer Website nachvollziehen.
              </li>
              <li>
                Vorhersagemodellierung: Auf Grundlage erhobener Daten können durch maschinelles Lernen fehlende Daten
                hochgerechnet werden, die zukünftige Ereignisse und Trends vorhersagen. Das kann uns helfen, bessere
                Marketingstrategien zu entwickeln.
              </li>
              <li>
                Cross-Plattform-Analyse: Die Erfassung und Analyse von Daten sind sowohl von Websites als auch von Apps
                möglich. Das bietet uns die Möglichkeit, das Userverhalten plattformübergreifend zu analysieren, sofern
                Sie natürlich der Datenverarbeitung eingewilligt haben.
              </li>
            </ul>
            <h4 class="text-light">Warum verwenden wir Google Analytics auf unserer Website?</h4>
            <p>
              Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen den bestmöglichen Service bieten. Die Statistiken
              und Daten von Google Analytics helfen uns dieses Ziel zu erreichen.
            </p>
            <p>
              Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den Stärken und Schwächen unserer
              Website. Einerseits können wir unsere Seite so optimieren, dass sie von interessierten Menschen auf Google
              leichter gefunden wird. Andererseits helfen uns die Daten, Sie als Besucher besser zu verstehen. Wir
              wissen somit sehr genau, was wir an unserer Website verbessern müssen, um Ihnen das bestmögliche Service
              zu bieten. Die Daten dienen uns auch, unsere Werbe- und Marketing-Maßnahmen individueller und
              kostengünstiger durchzuführen. Schließlich macht es nur Sinn, unsere Produkte und Dienstleistungen
              Menschen zu zeigen, die sich dafür interessieren.
            </p>
            <h4 class="text-light">Welche Daten werden von Google Analytics gespeichert?</h4>
            <p>
              Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige, eindeutige ID, die mit Ihrem
              Browser-Cookie verbunden ist. So erkennt Sie Google Analytics als neuen User und Ihnen wird eine User-ID
              zugeordnet. Wenn Sie das nächste Mal unsere Seite besuchen, werden Sie als „wiederkehrender“ User erkannt.
              Alle gesammelten Daten werden gemeinsam mit dieser User-ID gespeichert. So ist es erst möglich pseudonyme
              Userprofile auszuwerten.
            </p>
            <p>
              Um mit Google Analytics unsere Website analysieren zu können, muss eine Property-ID in den Tracking-Code
              eingefügt werden. Die Daten werden dann in der entsprechenden Property gespeichert. Für jede neu angelegte
              Property ist die Google Analytics 4-Property standardmäßig. Je nach verwendeter Property werden Daten
              unterschiedlich lange gespeichert.
            </p>
            <p>
              Durch Kennzeichnungen wie Cookies, App-Instanz-IDs, User-IDs oder etwa benutzerdefinierte
              Ereignisparameter werden Ihre Interaktionen, sofern Sie eingewilligt haben, plattformübergreifend
              gemessen. Interaktionen sind alle Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie
              auch andere Google-Systeme (wie z.B. ein Google-Konto) nützen, können über Google Analytics generierte
              Daten mit Drittanbieter-Cookies verknüpft werden. Google gibt keine Google Analytics-Daten weiter, außer
              wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen, wenn es gesetzlich erforderlich ist.
            </p>
            <p>
              Laut Google werden in Google Analytics 4 keine IP-Adressen protokolliert oder gespeichert. Google nutzt
              die IP-Adressdaten allerdings für die Ableitung von Standortdaten und löscht sie unmittelbar danach. Alle
              IP-Adressen, die von Usern in der EU erhoben werden, werden also gelöscht, bevor die Daten in einem
              Rechenzentrum oder auf einem Server gespeichert werden.
            </p>
            <p>
              Da bei Google Analytics 4 der Fokus auf ereignisbasierten Daten liegt, verwendet das Tool im Vergleich zu
              früheren Versionen (wie Google Universal Analytics) deutlich weniger Cookies. Dennoch gibt es einige
              spezifische Cookies, die von GA4 verwendet werden. Dazu zählen zum Beispiel:
            </p>
            <p>
              <strong>Name:</strong> _ga<br />
              <strong>Wert: </strong>2.1326744211.152312557438-5<br />
              <strong>Verwendungszweck:</strong> Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu
              speichern. Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher.<br />
              <strong>Ablaufdatum:</strong> nach 2 Jahren
            </p>
            <p>
              <strong>Name:</strong> _gid<br />
              <strong>Wert: </strong>2.1687193234.152312557438-1<br />
              <strong>Verwendungszweck:</strong> Das Cookie dient auch zur Unterscheidung der Webseitenbesucher<br />
              <strong>Ablaufdatum:</strong> nach 24 Stunden
            </p>
            <p>
              <strong>Name:</strong> _gat_gtag_UA_&lt;property-id&gt;<br />
              <strong>Wert:</strong> 1<br />
              <strong>Verwendungszweck:</strong> Wird zum Senken der Anforderungsrate verwendet. Wenn Google Analytics
              über den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_
              &lt;property-id&gt;.<br />
              <strong>Ablaufdatum: </strong>nach 1 Minute
            </p>
            <p>
              <strong>Anmerkung:</strong> Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google
              die Wahl ihrer Cookies immer wieder auch verändert. Ziel von GA4 ist es auch, den Datenschutz zu
              verbessern. Daher bietet das Tool einige Möglichkeiten zur Kontrolle der Datenerfassung. So können wir
              beispielsweise die Speicherdauer selbst festlegen und auch die Datenerfassung steuern.
            </p>
            <p>
              Hier zeigen wir Ihnen einen Überblick über die wichtigsten Arten von Daten, die mit Google Analytics
              erhoben werden:
            </p>
            <p>
              <strong>Heatmaps:</strong> Google legt sogenannte Heatmaps an. Über Heatmaps sieht man genau jene
              Bereiche, die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer Seite „unterwegs“ sind.
            </p>
            <p>
              <strong>Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer Seite
              verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung automatisch.
            </p>
            <p>
              <strong>Absprungrate</strong> (engl. Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf unserer
              Website nur eine Seite ansehen und dann unsere Website wieder verlassen.
            </p>
            <p>
              <strong>Kontoerstellung:</strong> Wenn Sie auf unserer Website ein Konto erstellen bzw. eine Bestellung
              machen, erhebt Google Analytics diese Daten.
            </p>
            <p>
              <strong>Standort:</strong> IP-Adressen werden in Google Analytics nicht protokolliert oder gespeichert.
              Allerdings werden kurz vor der Löschung der IP-Adresse Ableitungen für Standortdaten genutzt.
            </p>
            <p>
              <strong>Technische Informationen:</strong> Zu den technischen Informationen zählen unter anderem Ihr
              Browsertyp, Ihr Internetanbieter oder Ihre Bildschirmauflösung.
            </p>
            <p>
              <strong>Herkunftsquelle:</strong> Google Analytics beziehungsweise uns interessiert natürlich auch über
              welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.
            </p>
            <p>
              Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z. B., wenn Sie ein Video
              über unsere Seite abspielen), das Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren
              Favoriten. Die Aufzählung hat keinen Vollständigkeitsanspruch und dient nur zu einer allgemeinen
              Orientierung der Datenspeicherung durch Google Analytics.
            </p>
            <h4 class="text-light">Wie lange und wo werden die Daten gespeichert?</h4>
            <p>
              Google hat ihre Server auf der ganzen Welt verteilt. Hier können Sie genau nachlesen, wo sich die
              Google-Rechenzentren befinden:
              <a class="text-light" href="https://www.google.com/about/datacenters/locations/?hl=de"
                >https://www.google.com/about/datacenters/locations/?hl=de</a
              >
            </p>
            <p>
              Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Das hat den Vorteil, dass die Daten
              schneller abrufbar sind und vor Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt es
              entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise die Hardware bei Google ausfällt oder
              Naturkatastrophen Server lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google dennoch gering.
            </p>
            <p>
              Die Aufbewahrungsdauer der Daten hängt von den verwendeten Properties ab. Die Speicherdauer wird stets für
              jede einzelne Property eigens festgelegt. Google Analytics bietet uns zur Kontrolle der Speicherdauer vier
              Optionen an:
            </p>
            <ul>
              <li>2 Monate: das ist die kürzeste Speicherdauer.</li>
              <li>14 Monate: standardmäßig bleiben die Daten bei GA4 für 14 Monate gespeichert.</li>
              <li>26 Monate: man kann die Daten auch 26 Monate lang speichern.</li>
              <li>Daten werden erst gelöscht, wenn wir sie manuell löschen</li>
            </ul>
            <p>
              Zusätzlich gibt es auch die Option, dass Daten erst dann gelöscht werden, wenn Sie innerhalb des von uns
              gewählten Zeitraums nicht mehr unsere Website besuchen. In diesem Fall wird die Aufbewahrungsdauer jedes
              Mal zurückgesetzt, wenn Sie unsere Website innerhalb des festgelegten Zeitraums wieder besuchen.
            </p>
            <p>
              Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gelöscht. Diese
              Aufbewahrungsdauer gilt für Ihre Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der
              DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf aggregierten Daten und werden
              unabhängig von Nutzerdaten gespeichert. Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu
              einer größeren Einheit.
            </p>
            <h4 class="text-light">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h4>
            <p>
              Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre Daten zu
              erhalten, sie zu aktualisieren, zu löschen oder einzuschränken. Mithilfe des Browser-Add-ons zur
              Deaktivierung von Google Analytics-JavaScript (analytics.js, gtag.js) verhindern Sie, dass Google
              Analytics 4 Ihre Daten verwendet. Das Browser-Add-on können Sie unter
              <a class="text-light" href="https://tools.google.com/dlpage/gaoptout?hl=de"
                >https://tools.google.com/dlpage/gaoptout?hl=de</a
              >
              runterladen und installieren. Beachten Sie bitte, dass durch dieses Add-on nur die Datenerhebung durch
              Google Analytics deaktiviert wird.
            </p>
            <p>
              Falls Sie grundsätzlich Cookies deaktivieren, löschen oder verwalten wollen, finden Sie unter dem
              Abschnitt „Cookies“ die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.
            </p>
            <p>
              <strong>Rechtsgrundlage</strong>
            </p>
            <p>
              Der Einsatz von Google Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup
              eingeholt haben. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong>
              die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch
              Web-Analytics Tools vorkommen kann, dar.
            </p>
            <p>
              Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, dass Verhalten der
              Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe
              von Google Analytics erkennen wir Fehler der Website, können Attacken identifizieren und die
              Wirtschaftlichkeit verbessern. Die Rechtsgrundlage dafür ist
              <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google Analytics
              gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.
            </p>
            <p>
              Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des
              Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht.
              Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung
              einhergehen.
            </p>
            <p>
              Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen
              Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin
              verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO).
              Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte
              Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards
              entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert
              werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das
              europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und
              verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden
              den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier:
              <a
                class="text-light"
                href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
                target="_blank"
                rel="follow noopener"
                >https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a
              >
            </p>
            <p>
              Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die
              Standardvertragsklauseln verweisen, finden Sie unter
              <a
                class="text-light"
                href="https://business.safety.google/intl/de/adsprocessorterms/"
                target="_blank"
                rel="follow noopener"
                >https://business.safety.google/intl/de/adsprocessorterms/</a
              >.
            </p>
            <p>
              Wir hoffen, wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung von Google
              Analytics näherbringen. Wenn Sie mehr über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden
              Links:
              <a
                class="text-light"
                href="https://marketingplatform.google.com/about/analytics/terms/de/"
                target="_blank"
                rel="follow noopener"
                >https://marketingplatform.google.com/about/analytics/terms/de/</a
              >
              und
              <a
                class="text-light"
                href="https://support.google.com/analytics/answer/6004245?hl=de"
                target="_blank"
                rel="follow noopener"
                >https://support.google.com/analytics/answer/6004245?hl=de</a
              >.
            </p>
            <p>
              Wenn Sie mehr über die Datenverarbeitung erfahren wollen, nutzen Sie die Google-Datenschutzerklärung auf
              <a
                class="text-light"
                href="https://policies.google.com/privacy?hl=de&amp;tid=312557438"
                target="_blank"
                rel="follow noopener"
                >https://policies.google.com/privacy?hl=de</a
              >.
            </p>
            <h4 class="text-light" id="auftragsverarbeitungsvertrag-avv-google-analytics">
              Auftragsverarbeitungsvertrag (AVV) Google Analytics
            </h4>
            <p>
              Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit Google einen
              Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV
              enthalten sein muss, können Sie in unserem allgemeinen Abschnitt „Auftragsverarbeitungsvertrag (AVV)“
              nachlesen.
            </p>
            <p>
              Dieser Vertrag ist gesetzlich vorgeschrieben, weil Google in unserem Auftrag personenbezogene Daten
              verarbeitet. Darin wird geklärt, dass Google Daten, die sie von uns erhalten, nur nach unserer Weisung
              verarbeiten darf und die DSGVO einhalten muss. Den Link zu den Auftragsdatenverarbeitungsbedingungen
              finden Sie unter
              <a
                class="text-light"
                href="https://business.safety.google/intl/de/adsprocessorterms/"
                target="_blank"
                rel="follow noopener"
                >https://business.safety.google/intl/de/adsprocessorterms/</a
              >
            </p>
            <h4 class="text-light" id="google-analytics-berichte-zu-demografischen-merkmalen-und-interessen">
              Google Analytics Berichte zu demografischen Merkmalen und Interessen
            </h4>
            <p>
              Wir haben in Google Analytics die Funktionen für Werbeberichte eingeschaltet. Die Berichte zu
              demografischen Merkmalen und Interessen enthalten Angaben zu Alter, Geschlecht und Interessen. Damit
              können wir uns &#8211; ohne diese Daten einzelnen Personen zuordnen zu können &#8211; ein besseres Bild
              von unseren Nutzern machen. Mehr über die Werbefunktionen erfahren Sie auf
              <a class="text-light" href="https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad"
                >https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad</a
              >.
            </p>
            <p>
              Sie können die Nutzung der Aktivitäten und Informationen Ihres Google Kontos unter “Einstellungen für
              Werbung” auf
              <a class="text-light" href="https://adssettings.google.com/authenticated"
                >https://adssettings.google.com/authenticated</a
              >
              per Checkbox beenden.
            </p>
            <h4 class="text-light" id="google-analytics-e-commerce-messung">Google Analytics E-Commerce-Messung</h4>
            <p>
              Wir verwenden für unsere Website auch die E-Commerce-Messung des Webanalysetools Google Analytics. Damit
              können wir sehr genau analysieren, wie Sie und all unsere anderen Kunden auf unserer Website interagieren.
              Bei der E-Commerce-Messung geht es vor allem um das Kaufverhalten. Anhand gewonnener Daten können wir
              unseren Dienst an Ihre Wünsche und Ihre Erwartungen anpassen und optimieren. Ebenso können wir unsere
              Online-Werbemaßnahmen zielgerichteter einsetzen, damit unsere Werbung nur Menschen sehen, die sich auch
              für unsere Produkte bzw. Dienstleistungen interessieren. Die E-Commerce-Messung erfasst zum Beispiel
              welche Bestellungen getätigt wurden, wie lange es dauerte, bis Sie das Produkt erworben haben, wie hoch
              der durchschnittliche Bestellwert ist oder auch wie hoch die Versandkosten sind. All diese Daten können
              unter einer bestimmten ID erfasst und gespeichert werden.
            </p>
            <h4 class="text-light" id="google-analytics-im-einwilligungsmodus">
              Google Analytics im Einwilligungsmodus
            </h4>
            <p>
              Abhängig von Ihrer Einwilligung werden im sogenannten Einwilligungsmodus (bzw. „Consent Mode“)
              personenbezogene Daten von Ihnen durch Google Analytics verarbeitet. Sie können wählen, ob Sie
              Google-Analytics-Cookies zustimmen oder nicht. Damit wählen Sie auch, welche Daten Google Analytics von
              Ihnen verarbeitet darf. Diese erhobenen Daten werden hauptsächlich dafür verwendet, Messungen über das
              Userverhalten auf der Website durchzuführen, zielgerichtete Werbung auszuspielen und uns
              Web-Analyseberichte zu liefern. In der Regel willigen Sie der Datenverarbeitung durch Google über ein
              Cookie-Consent-Tool ein. Wenn Sie der Datenverarbeitung nicht einwilligen, werden nur aggregierte Daten
              erfasst und verarbeitet. Das bedeutet, Daten können einzelnen Usern nicht zugeordnet werden und es
              entsteht somit kein Userprofil von Ihnen. Sie können auch nur der statistischen Messung zustimmen. Dabei
              werden keine personenbezogenen Daten verarbeitet und folglich nicht für Werbungen oder Werbemesserfolge
              verwendet.
            </p>
            <h4 class="text-light" id="google-analytics-ip-anonymisierung">Google Analytics IP-Anonymisierung</h4>
            <p>
              Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von Google Analytics implementiert. Diese
              Funktion wurde von Google entwickelt, damit diese Webseite die geltenden Datenschutzbestimmungen und
              Empfehlungen der lokalen Datenschutzbehörden einhalten kann, wenn diese eine Speicherung der vollständigen
              IP-Adresse untersagen. Die Anonymisierung bzw. Maskierung der IP findet statt, sobald die IP-Adressen im
              Google Analytics-Datenerfassungsnetzwerk eintreffen und bevor eine Speicherung oder Verarbeitung der Daten
              stattfindet.
            </p>
            <p>
              Mehr Informationen zur IP-Anonymisierung finden Sie auf
              <a
                class="text-light"
                href="https://support.google.com/analytics/answer/2763052?hl=de"
                target="_blank"
                rel="noopener"
                >https://support.google.com/analytics/answer/2763052?hl=de</a
              >.
            </p>
            <h4 class="text-light" id="google-analytics-ohne-cookies">Google Analytics ohne Cookies</h4>
            <p>
              Wir nutzen auf unserer Website zwar Google Analytics (kurz GA), allerdings ohne Cookies in Ihrem Browser
              zu setzen. Was Cookies sind, haben wir bereits weiter oben erklärt, hoffentlich sind die Ausführungen noch
              im Gedächtnis. Nur kurz und konkret auf GA bezogen: Durch Cookies werden für GA hilfreiche Daten in Ihrem
              Browser auf Ihrem Gerät gespeichert. Durch den Entfall der Nutzung von Cookies werden gerade keine
              personenbezogenen Daten in solchen Cookies gespeichert, die ein Nutzerprofil begründen werden. Google
              Analytics kann zwar diverse Messungen und Webanalysen durchführen, jedoch werden die dafür erhobenen Daten
              lediglich auf den Google-Servern gespeichert und Ihre Privatsphäre wird deutlich stärker respektiert und
              geschützt.
            </p>
            <h4 class="text-light" id="google-optimize-datenschutzerklaerung">Google Optimize Datenschutzerklärung</h4>
            <p>
              Wir verwenden auf unserer Website Google Optimize, ein Website-Optimierungstool. Dienstanbieter ist das
              amerikanische Unternehmen Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited
              (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.
            </p>
            <p>
              Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des
              Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht.
              Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung
              einhergehen.
            </p>
            <p>
              Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen
              Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin
              verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO).
              Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte
              Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards
              entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert
              werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das
              europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und
              verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden
              den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier:
              <a class="text-light" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
                >https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a
              >
            </p>
            <p>
              Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die
              Standardvertragsklauseln verweisen, finden Sie unter
              <a
                class="text-light"
                href="https://business.safety.google/intl/de/adsprocessorterms/"
                target="_blank"
                rel="follow noopener"
                >https://business.safety.google/intl/de/adsprocessorterms/</a
              >.
            </p>
            <p>
              Mehr über die Daten, die durch die Verwendung von Google Optimize verarbeitet werden, erfahren Sie in der
              Privacy Policy auf
              <a
                class="text-light"
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="follow noopener"
                >https://policies.google.com/privacy?hl=de</a
              >.
            </p>
            <h4 class="text-light" id="auftragsverarbeitungsvertrag-avv-google-optimize">
              Auftragsverarbeitungsvertrag (AVV) Google Optimize
            </h4>
            <p>
              Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit Google einen
              Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV
              enthalten sein muss, können Sie in unserem allgemeinen Abschnitt „Auftragsverarbeitungsvertrag (AVV)“
              nachlesen.
            </p>
            <p>
              Dieser Vertrag ist gesetzlich vorgeschrieben, weil Google in unserem Auftrag personenbezogene Daten
              verarbeitet. Darin wird geklärt, dass Google Daten, die sie von uns erhalten, nur nach unserer Weisung
              verarbeiten darf und die DSGVO einhalten muss. Den Link zu den Auftragsdatenverarbeitungsbedingungen
              finden Sie unter
              <a
                class="text-light"
                href="https://business.safety.google/intl/de/adsprocessorterms/"
                target="_blank"
                rel="follow noopener"
                >https://business.safety.google/intl/de/adsprocessorterms/</a
              >
            </p>
            <h4 class="text-light" id="google-site-kit-datenschutzerklaerung">Google Site Kit Datenschutzerklärung</h4>
            <table border="1" cellpadding="15">
              <tbody>
                <tr>
                  <td>
                    <strong>Google Site Kit Datenschutzerklärung Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br />
                    &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe,
                    Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen
                    enthalten. Mehr Details dazu finden weiter unten und in der Datenschutzerklärung von Google
                    Analytics.<br />
                    &#x1f4c5; Speicherdauer: abhängig von den verwendeten Properties<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f
                    DSGVO (Berechtigte Interessen)
                  </td>
                </tr>
              </tbody>
            </table>
            <h4 class="text-light">Was ist Google Site Kit?</h4>
            <p>
              Wir haben in unsere Website das WordPress-Plugin Google Site Kit des amerikanischen Unternehmens Google
              Inc. eingebunden. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House,
              Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit Google Site Kit können wir
              schnell und einfach Statistiken, die aus diversen Google-Produkten wie Google Analytics stammen, direkt in
              unserem WordPress-Dashboard ansehen. Das Tool beziehungsweise die in Google Site Kit eingebundenen Tools
              sammeln unter anderem auch personenbezogene Daten von Ihnen. In dieser Datenschutzerklärung erklären wir
              Ihnen, warum wir Google Site Kit verwenden, wie lange und wo Daten gespeichert werden und welche weiteren
              Datenschutztexte in diesem Zusammenhang für Sie relevant sind.
            </p>
            <p>
              Google Site Kit ist ein Plugin für das Content-Management-System WordPress. Mit diesem Plugin können wir
              wichtige Statistiken zur Websiteanalyse direkt in unserem Dashboard ansehen. Dabei handelt es sich um
              Statistiken, die von anderen Google-Produkten erhoben werden. Allen voran von Google Analytics. Neben
              Google Analytics können auch noch die Services Google Search Console, Page Speed Insight, Google AdSense,
              Google Optimize und Google Tag Manager mit Google Site Kit verknüpft werden.
            </p>
            <h4 class="text-light">Warum verwenden wir Google Site Kit auf unserer Website?</h4>
            <p>
              Als Dienstleister ist es unsere Aufgabe, Ihnen auf unserer Website das bestmögliche Erlebnis zu bieten.
              Sie sollen sich auf unserer Website wohl fühlen und schnell und einfach genau das finden, was Sie suchen.
              Statistische Auswertungen helfen uns dabei, sie besser kennen zu lernen und unser Angebot an Ihre Wünsche
              und Interessen anzupassen. Für diese Auswertungen nutzen wir verschiedene Google-Tools. Site Kit
              erleichtert diesbezüglich unsere Arbeit sehr, weil wir die Statistiken der Google-Produkte gleich im
              Dashboard ansehen und analysieren können. Wir müssen uns also nicht mehr für das jeweilige Tool extra
              anmelden. Site Kit bietet somit immer einen guten Überblick über die wichtigsten Analyse-Daten.
            </p>
            <h4 class="text-light">Welche Daten werden von Google Site Kit gespeichert?</h4>
            <p>
              Wenn Sie im Cookie-Hinweis (auch Script oder Banner genannt) Trackingtools aktiv zugestimmt haben, werden
              durch Google-Produkte wie Google Analytics Cookies gesetzt und Daten von Ihnen, etwa über Ihr
              Userverhalten, an Google gesendet, dort gespeichert und verarbeitet. Darunter werden auch personenbezogen
              Daten wie Ihre IP-Adresse gespeichert.
            </p>
            <p>
              Für genauere Informationen zu den einzelnen Diensten haben wir eigenen Textabschnitte in dieser
              Datenschutzerklärung. Sehen Sie sich beispielsweise unsere Datenschutzerklärung zu Google Analytics an.
              Hier gehen wir sehr genau auf die erhobenen Daten ein. Sie erfahren wie lange Google Analytics Daten
              speichert, verwaltet und verarbeitet, welche Cookies zum Einsatz kommen können und wie Sie die
              Datenspeicherung verhindern. Ebenso haben wir auch für weitere Google-Dienste wie etwa den Google Tag
              Manager oder Google AdSense eigene Datenschutzerklärungen mit umfassenden Informationen.
            </p>
            <p>
              Im Folgenden zeigen wir Ihnen beispielhafte Google-Analytics-Cookies, die in Ihrem Browser gesetzt werden
              können, sofern Sie der Datenverarbeitung durch Google grundsätzlich zugestimmt haben. Bitte beachten Sie,
              dass es sich bei diesen Cookies lediglich um eine Auswahl handelt:
            </p>
            <p>
              <strong>Name:</strong> _ga<br />
              <strong>Wert:</strong>2.1326744211.152312557438-2<br />
              <strong>Verwendungszweck:</strong> Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu
              speichern. Grundsätzlich dient es zur Unterscheidung der Websitenbesucher.<br />
              <strong>Ablaufdatum:</strong> nach 2 Jahren
            </p>
            <p>
              <strong>Name:</strong> _gid<br />
              <strong>Wert:</strong>2.1687193234.152312557438-7<br />
              <strong>Verwendungszweck:</strong> Auch dieses Cookie dient der Unterscheidung von Websitesbesuchern.<br />
              <strong>Ablaufdatum:</strong> nach 24 Stunden
            </p>
            <p>
              <strong>Name:</strong> _gat_gtag_UA_&lt;property-id&gt;<br />
              <strong>Wert:</strong> 1<br />
              <strong>Verwendungszweck:</strong> Dieses Cookie wird zum Senken der Anforderungsrate verwendet.<br />
              <strong>Ablaufdatum: </strong>nach 1 Minute
            </p>
            <h4 class="text-light">Wie lange und wo werden die Daten gespeichert?</h4>
            <p>
              Google speichert erhobene Daten auf eigenen Google-Servern, die weltweit verteilt sind. Die meisten Server
              befinden sich in den Vereinigten Staaten und daher ist es leicht möglich, dass Ihre Daten auch dort
              gespeichert werden. Auf
              <a
                class="text-light"
                href="https://www.google.com/about/datacenters/locations/?hl=de"
                target="_blank"
                rel="follow noopener"
                >https://www.google.com/about/datacenters/locations/?hl=de</a
              >
              sehen Sie genau, wo das Unternehmen Server bereitstellt.
            </p>
            <p>
              Daten, die durch Google Analytics erhoben werden, werden standardisiert 26 Monate aufbewahrt. Im Anschluss
              werden Ihre Userdaten gelöscht. Die Aufbewahrungsdauer gilt für alle Daten, die mit Cookies, Usererkennung
              und Werbe-IDs verknüpft sind.
            </p>
            <h4 class="text-light">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h4>
            <p>
              Sie haben immer das Recht, Auskunft über Ihre Daten zu erhalten, Ihre Daten löschen, berichtigen oder
              einschränken zu lassen. Zudem können Sie auch in Ihrem Browser Cookies jederzeit deaktivieren, löschen
              oder verwalten.
            </p>
            <p>
              Falls Sie grundsätzlich Cookies deaktivieren, löschen oder verwalten wollen, finden Sie unter dem
              Abschnitt „Cookies“ die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.
            </p>
            <h4 class="text-light">Rechtsgrundlage</h4>
            <p>
              Der Einsatz von Google Site Kit setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup
              eingeholt haben. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong>
              die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch
              Web-Analytics Tools vorkommen kann, dar.
            </p>
            <p>
              Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, dass Verhalten der
              Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe
              von Google Site Kit erkennen wir Fehler der Website, können Attacken identifizieren und die
              Wirtschaftlichkeit verbessern. Die Rechtsgrundlage dafür ist
              <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google Site Kit
              gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.
            </p>
            <p>
              Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des
              Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht.
              Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung
              einhergehen.
            </p>
            <p>
              Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen
              Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin
              verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO).
              Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte
              Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards
              entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert
              werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das
              europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und
              verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden
              den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier:
              <a
                class="text-light"
                href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
                target="_blank"
                rel="noopener"
                >https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a
              >
            </p>
            <p>
              Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die
              Standardvertragsklauseln verweisen, finden Sie unter
              <a
                class="text-light"
                href="https://business.safety.google/intl/de/adsprocessorterms/"
                target="_blank"
                rel="follow noopener"
                >https://business.safety.google/intl/de/adsprocessorterms/</a
              >
            </p>
            <p>
              Um mehr über die Datenverarbeitung durch Google zu erfahren, empfehlen wir Ihnen die umfassenden
              Datenschutzrichtlinien von Google unter
              <a class="text-light" href="https://policies.google.com/privacy?hl=de?tid=312557438"
                >https://policies.google.com/privacy?hl=de</a
              >.
            </p>
            <p>Alle Texte sind urheberrechtlich geschützt.</p>
            <p style="margin-top: 15px">
              Quelle: Erstellt mit dem
              <a
                class="text-light"
                href="https://www.adsimple.de/datenschutz-generator/"
                title="Datenschutz Generator von AdSimple für Deutschland"
                >Datenschutz Generator</a
              >
              von AdSimple
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DatenschutzView",
};
</script>
