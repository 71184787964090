<template>
  <div class="col mainContent">
    <div class="row p-2 pt-2">
      <div
        class="p-2 pt-4 pt-lg-3 streamerCard d-flex align-items-stretch"
        v-for="stream in this.$store.getters.gtav_getStreams.streams"
        :key="stream.title"
        @click="handleClick(stream)"
      >
        <div class="mb-0 pb-0 position-relative">
          <img
            src="https://i.ibb.co/2sQkFXd/placeholder.jpg"
            alt=""
            class="placeholder-glow card-img-top rounded-0"
            v-if="!this.loadedUrls.includes(stream.thumbnail_url)"
          />
          <img
            :src="stream.thumbnail_url"
            :title="stream.title"
            :alt="stream.title"
            @load="this.loadedUrls.push(stream.thumbnail_url)"
            class="card-img-top rounded-0"
          />
          <span
            class="z-3 position-absolute top-0 start-0 translate-middle badge badgePosition p-1 rounded-0 bg-danger font-monospace fs-6"
            >LIVE</span
          >
          <div class="streamerViewer d-flex flex-column">
            <span class="streamerViewerCount">
              <img class="viewerIcon" src="https://i.ibb.co/zrtCSkT/group.png" />
              {{ formatNumber(stream.viewer_count) }}
            </span>
          </div>
          <div class="d-flex pt-2 p-0">
            <div class="mx-auto p-1 m-0">
              <img :title="stream.user_name" class="img-fluid rounded-circle mt-1" :src="stream.profile_image_url" />
            </div>
            <div class="col-10 p-0 m-0 ps-2">
              <p class="p-0 m-0 fw-bold" :title="stream.title">
                {{ formatTitle(stream.title) }}
              </p>
              <h6 class="fs-6 text-secondary fw-normal" :title="stream.user_name">
                {{ stream.user_name.length > 20 ? stream.user_name.substring(0, 20) + "..." : stream.user_name }}
              </h6>
              <p class="cardTags" :title="stream.tags">
                {{ stream.tags.length > 25 ? stream.tags.substring(0, 25) + "..." : stream.tags }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      class="modal bd-example-modal-lg fade p-0 m-0"
      id="liveStream"
      tabindex="-1"
      aria-labelledby="liveStreamLabel"
      aria-hidden="true"
      data-bs-backdrop="false"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered" style="max-width: 100%">
        <div class="modal-content border border-info bg-dark">
          <div class="modal-header mt-1 pt-1 pointer border-0 d-flex justify-content-between">
            <div>
              <h1 class="modal-title fs-6 pt-1 pt-sm-0" id="liveStreamLabel">{{ selectedStreamer }}</h1>
            </div>
            <div class="form-check form-switch d-flex align-items-center text-secondary mt-0 pt-0">
              <input
                class="form-check-input shadow-none pointer"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                v-model="chatVisible"
              /><span class="pt-1">&nbsp;Twitch-Chat</span>
            </div>
            <div class="closeButton pt-3 pt-sm-0">
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div class="modal-body pt-0">
            <iframe
              :src="`https://player.twitch.tv/?channel=${selectedStreamer}&parent=localhost&muted=false`"
              height="520px"
              width="100%"
              allowfullscreen
            >
            </iframe>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import gtav from "@/services/gtav";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      page: 1,
      loadedUrls: [],
      selectedStreamer: "",
      chatVisible: true,
    };
  },
  watch: {
    "$route.params.server"(servername) {
      this.$store.dispatch("gtavSetStreams", {
        currentPage: 1,
        totalPages: 1,
        totalResults: 216,
        streams: [],
      });
      gtav.getStreamsByServername(servername, 1);
    },
  },
  async mounted() {
    this.$store.dispatch("gtavSetStreams", {
      currentPage: 1,
      totalPages: 1,
      totalResults: 216,
      streams: [],
    });
    setInterval(this.refreshImages, 300000)
    await gtav.getStreamsByServername(this.$route.params.server, 1);
    // await gtav.getRandomStreams();
    //window.addEventListener("scroll", this.checkLazyLoad);
  },
  methods: {
    refreshImages() {
  // Get all image elements on the page
  const images = document.querySelectorAll('img');

  images.forEach(image => {
    // Append a timestamp to the image src to force reload
    const src = image.src;
    const newSrc = src.split('?')[0] + '?t=' + new Date().getTime();
    image.src = newSrc;
  });
},
    handleClick(stream) {
      /*
       new Twitch.Embed("twitch-embed", {
        width: "100%",
        height: "100%",
        channel: stream.user_name,
        layout: this.chatVisible ? "video-with-chat" : "video",
        theme: "dark",
        autoplay: true,
        allowfullscreen: true,
        // Only needed if this page is going to be embedded on other websites
        parent: ["localhost", "rpstreams.com", "rpstreams.de", "test.rpstreams.de"],
      });
      */
      //console.log(stream);
      this.selectedStreamer = stream.user_name;
      let modals = this.$store.getters.gtav_getStreamerModals;
      if (!modals.includes(stream)) {
        modals.push(stream);
        this.$store.dispatch("gtavSetStreamerModals", modals);
        setTimeout(() => {
          let modal = document.getElementById(`joeTemplate_${this.selectedStreamer}`);
          modal.style.zIndex = this.findHighestZIndex() + 1;
        }, 100);
      }
    },
    findHighestZIndex() {
      const elements = document.querySelectorAll(".custom-modal");
      let highestZIndex = 9999;

      elements.forEach((element) => {
        const zIndex = getComputedStyle(element).zIndex;
        const zIndexValue = parseInt(zIndex, 10);

        if (!isNaN(zIndexValue) && zIndexValue > highestZIndex) {
          highestZIndex = zIndexValue;
        }
      });

      return highestZIndex;
    },
    formatNumber(number) {
      return gtav.formatNumber(number);
    },
    // checkLazyLoad() {
    //   if (window.innerHeight + window.scrollY >= document.body.offsetHeight-100) {
    //     if (this.page >= this.$store.getters.gtav_getStreams.totalPages) {
    //       return;
    //     }
    //     this.lazyLoadMore();
    //   }
    // },
    // lazyLoadMore() {
    //   this.page = this.page + 1;
    //   gtav.getStreamsByServername(this.$route.params.server, this.page, true);
    // },
    formatTitle(title) {
      if (title.length > 20) {
        title = title.substring(0, 20) + "...";
      }
      var words = title.split(" ");
      for (var j = 0; j < words.length; j++) {
        if (words[j].length > 10 && words[j] === words[j].toUpperCase()) {
          words[j] = words[j].toLowerCase();
        }
      }

      title = words.join(" ");

      return title;
    },
  },
};
</script>
