import { createStore } from "vuex";

export default createStore({
  state: {
    gtav: {
      loading: {
        topservers: false,
        server_analytics: false,
      },
      serverlist: {
        allStreamers: {
          totalviewer_count: 0,
          liveCount: 0,
        },
      },
      random_streams: [],
      streams: {
        currentPage: 1,
        totalPages: 10,
        totalResults: 216,
        streams: [],
      },
      topclips: [],
      topservers: {
        serverData: [],
        avgViewersTotal: 0,
        avgStreamersTotal: 0,
      },
      search_results: [],
      search_error: null,
      server_analytics: {},
      streamer_modals: [],
      modal_options: {
        draggedStream: false,
        resizedStream: false,
        offsetX: null,
        offsetY: null,
        modalWidth: null,
        modalHeight: null,
      },
    },
  },
  getters: {
    gtav_getStreamerModals(state) {
      return state.gtav.streamer_modals;
    },
    gtav_getModalOptions(state) {
      return state.gtav.modal_options;
    },
    gtav_getServerList(state) {
      return state.gtav.serverlist;
    },
    gtav_getLoadingState(state){
      return state.gtav.loading;
    },
    gtav_getStreams(state) {
      return state.gtav.streams;
    },
    gtav_getRandomStreams(state) {
      return state.gtav.random_streams;
    },
    gtav_getTopClips(state) {
      return state.gtav.topclips;
    },
    gtav_getTopServers(state) {
      return state.gtav.topservers;
    },
    gtav_getSearchResults(state) {
      return state.gtav.search_results;
    },
    gtav_getSearchError(state) {
      return state.gtav.search_error;
    },
    gtav_getServerAnalytics(state) {
      return state.gtav.server_analytics;
    },
  },
  mutations: {
    GTAV_SET_SERVER_LIST(state, data) {
      state.gtav.serverlist = data;
    },
    GTAV_SET_STREAMS(state, data) {
      state.gtav.streams = data;
    },
    GTAV_SET_SEARCH_RESULTS(state, data) {
      state.gtav.search_results = data;
    },
    GTAV_SET_RANDOM_STREAMS(state, data) {
      state.gtav.random_streams = data;
    },
    GTAV_SET_TOP_CLIPS(state, data) {
      state.gtav.topclips = data;
    },
    GTAV_SET_TOP_SERVERS(state, data) {
      state.gtav.topservers = data;
    },
    GTAV_SET_SEARCH_ERROR(state, data) {
      state.gtav.search_error = data;
    },
    GTAV_SET_SERVER_ANALYTICS(state, data) {
      state.gtav.server_analytics = data;
    },
    GTAV_SET_STREAMER_MODALS(state, data) {
      state.gtav.streamer_modals = data;
    },
    GTAV_SET_MODAL_OPTIONS(state, data) {
      state.gtav.modal_options = data;
    },
    GTAV_SET_LOADING_STATE(state, { key, value }) {
      state.gtav.loading[key] = value;
    },
  },
  actions: {
    gtavSetLoadingState({ commit }, { key, value }){
      commit('GTAV_SET_LOADING_STATE', { key, value });
    },
    gtavSetServerList({ commit }, payload) {
      commit("GTAV_SET_SERVER_LIST", payload);
    },
    gtavSetStreams({ commit }, payload) {
      commit("GTAV_SET_STREAMS", payload);
    },
    gtavSetTopClips({ commit }, payload) {
      commit("GTAV_SET_TOP_CLIPS", payload);
    },
    gtavSetRandomStreams({ commit }, payload) {
      commit("GTAV_SET_RANDOM_STREAMS", payload);
    },
    gtavSetTopServers({ commit }, payload) {
      commit("GTAV_SET_TOP_SERVERS", payload);
    },
    gtavSetSearchResults({ commit }, payload) {
      commit("GTAV_SET_SEARCH_RESULTS", payload);
    },
    gtavSetSearchError({ commit }, payload) {
      commit("GTAV_SET_SEARCH_ERROR", payload);
    },
    gtavSetServerAnalytics({ commit }, payload) {
      commit("GTAV_SET_SERVER_ANALYTICS", payload);
    },
    gtavSetStreamerModals({ commit }, payload) {
      commit("GTAV_SET_STREAMER_MODALS", payload);
    },
    gtavSetModalOptions({ commit }, payload) {
      commit("GTAV_SET_MODAL_OPTIONS", payload);
    },
  },
  modules: {},
});
